import { is } from "ramda";

const DEFAULT_ERROR_MSG = "Unexpected error occurred";

export default class FetchUtils {
  public static findAnyError(
    response: Response,
    data: object | Array<object | string | number> | number
  ): Error | undefined {
    if (
      response.status >= 400 &&
      is(Object, data) &&
      !is(String, data) &&
      !is(Array, data)
    ) {
      const message = Object.entries(data as { [key: string]: Array<string> })
        .map((entry) => `${entry[0]}: "${entry[1]}"`)
        .join("\n");
      return new Error(message);
    } else if (response.status >= 400) {
      return new Error(DEFAULT_ERROR_MSG);
    }
    return undefined;
  }
}
