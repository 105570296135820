<template>
  <div class="navigation">
    <slot :toggledCollapse="toggledCollapse" :collapse="collapse"></slot>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Inject } from "vue-property-decorator";
import ISidebarStateService from "@/application/ISidebarStateService";

@Component
export default class WrapperView extends Vue {
  @Inject("ISidebarStateService") sidebarState!: ISidebarStateService;
  private collapse: boolean | null = null;

  mounted() {
    this.sidebarState.getCollapse().subscribe((collapse: boolean) => {
      this.collapse = collapse;
    });
  }
  get collapseState() {
    return this.collapse ?? false;
  }

  private toggledCollapse() {
    this.sidebarState.setCollapse(!this.collapse);
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";
#wrapper .contextual {
  /* CSS for contextual when wrapper element does not have toggle class */
  width: 100%;
}
</style>
