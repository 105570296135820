import IProfileService from "@/application/IProfileService";
import IProfileSummary from "@/domain/IProfileSummary";
import IProfile from "@/domain/IProfile";
import { inject, singleton } from "tsyringe";
import ISecurityService from "@/application/ISecurityService";
import ISubscription from "@/domain/ISubscription";
import IMessage from "@/domain/IMessage";
import IThread from "@/domain/IThread";
import FetchUtils from "@/infrastructure/FetchUtils";
import ITimeZone from "@/domain/ITimeZone";
import {
  BehaviorSubject,
  first,
  mergeMap,
  Observable,
  Subject,
  take,
} from "rxjs";
import { ajax } from "rxjs/internal/ajax/ajax";

type AdditionalInformationItemDTO = { field: string; value: string };

type ProfileDTO = {
  display_name?: string;
  full_name?: string;
  about_me?: string;
  additional_information?: Array<AdditionalInformationItemDTO>;
};

type ProfileSummaryDTO = {
  post_count: number;
  thread_count: number;
  vote_count: number;
  date_joined: string;
  display_name: string;
  image_url: string;
};

type SubscriptionDTO = {
  list_id: string;
  list_name: string;
  list_url: string;
  archived: boolean;
  roles?: string[];
  mailing_list?: {
    name: string;
    display_name: string;
    description: string;
    cover_image_url: string;
    banner_image_url: string;
  };
};

type PostDTO = {
  mailinglist: {
    name: string;
    display_name: string;
    description: string;
  };
  sender: {
    mailman_id: string;
    name: string;
    image_url: string;
  };
  message_id_hash: string;
  thread_id: string;
  subject: string;
  content: string;
  html_content: string;
  sender_name: string;
  date: string;
  get_votes: {
    likes: number;
    dislikes: number;
    status: string;
  };
  parent: object;
  flagged_by_moderator: boolean;
};

type ThreadDTO = {
  thread: {
    thread_id: string;
    starting_email: PostDTO;
    participants: Array<{
      mailman_id: string;
      name: string;
      image_url: string;
    }>;
    participants_count: number;
    emails_count: number;
    date_active: string;
  };
};

type VoteDTO = {
  email: PostDTO;
  value: number;
};

type TimeZoneDTO = {
  timezone: string;
};

const DEFAULT_HTTP_HEADERS = {
  "Content-Type": "application/json",
};

const CSRF_TOKEN_HEADER_NAME = "X-CSRFToken";
const DEFAULT_ERROR_MSG = "Unexpected error occurred";

@singleton()
export default class ProfileService implements IProfileService {
  private timeZone$ = new BehaviorSubject<ITimeZone | undefined>(undefined);
  private timeZoneRequestQueue$ = new Subject<void>();

  private subscriptions$ = new BehaviorSubject<
    Array<ISubscription> | undefined
  >(undefined);
  private subscriptionsRequestQueue$ = new Subject<void>();

  constructor(
    @inject("ISecurityService") private securityService?: ISecurityService
  ) {
    this.scheduleTimeZoneRequest();
    this.scheduleSubscriptionsRequest();
  }

  async getProfileSummary(): Promise<IProfileSummary> {
    const response = await fetch("/api/v1/profile-summary");
    const data = (await response.json()) as ProfileSummaryDTO;
    return {
      postCount: data.post_count,
      threadCount: data.thread_count,
      voteCount: data.vote_count,
      dateJoined: data.date_joined,
      displayName: data.display_name,
      imageUrl: data.image_url,
    };
  }

  async getProfile(): Promise<IProfile> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch("/api/v1/profile", { headers });
    const data = await response.json();

    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return ProfileService.toIProfile(data as ProfileDTO);
  }

  async save(profile: IProfile): Promise<IProfile> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };

    const csrfToken = this.securityService?.getToken();
    if (csrfToken) {
      headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;
    }

    const response = await fetch("/api/v1/profile", {
      method: "PUT",
      headers,
      body: JSON.stringify(ProfileService.toProfileDTO(profile)),
    });

    const data = await response.json();

    const error = await FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return ProfileService.toIProfile(data as ProfileDTO);
  }

  private static toIProfile(profileDTO: ProfileDTO): IProfile {
    return {
      displayName: profileDTO.display_name ?? "",
      fullName: profileDTO.full_name ?? "",
      aboutMe: profileDTO.about_me ?? undefined,
      additionalInformation: profileDTO.additional_information ?? [],
    };
  }

  private static toProfileDTO(profile: IProfile): ProfileDTO {
    return {
      about_me: profile.aboutMe,
    };
  }

  public getSubscriptions(): Observable<Array<ISubscription> | undefined> {
    this.subscriptionsRequestQueue$.next();
    return this.subscriptions$.asObservable();
  }

  private scheduleSubscriptionsRequest(): void {
    this.subscriptionsRequestQueue$
      .pipe(
        first(),
        mergeMap(() =>
          ajax.getJSON<Array<SubscriptionDTO>>("/api/v1/profile/subscriptions")
        )
      )
      .subscribe((subscriptions) => {
        this.subscriptions$.next(
          subscriptions.map(ProfileService.toISubscription)
        );
      });
  }

  private static toISubscription(
    subscriptionDTO: SubscriptionDTO
  ): ISubscription {
    return {
      listId: subscriptionDTO.list_id,
      listName: subscriptionDTO.list_name,
      listUrl: subscriptionDTO.list_url,
      archived: subscriptionDTO.archived,
      roles: subscriptionDTO.roles,
      displayName: subscriptionDTO.mailing_list?.display_name,
      description: subscriptionDTO.mailing_list?.description,
      coverImageUrl: subscriptionDTO.mailing_list?.cover_image_url,
      bannerImageUrl: subscriptionDTO.mailing_list?.banner_image_url,
    };
  }

  public async getLastPosts(): Promise<Array<IMessage>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch("/api/v1/profile/last-posts", { headers });
    const data = await response.json();

    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return (data as Array<PostDTO>).map(ProfileService.toIMessage);
  }

  private static toIMessage(post: PostDTO): IMessage {
    return {
      idHash: post.message_id_hash,
      subject: post.subject,
      content: post.content,
      htmlContent: post.html_content,
      sender: {
        mailmanId: post.sender.mailman_id,
        name: post.sender_name,
        imageUrl: post.sender.image_url,
      },
      date: post.date,
      likes: post.get_votes.likes,
      dislikes: post.get_votes.dislikes,
      mailingList: {
        name: post.mailinglist.name,
        displayName: post.mailinglist.display_name,
      },
      threadId: post.thread_id,
      flaggedByModerator: post.flagged_by_moderator,
    };
  }

  public async getLastReadThreads(): Promise<Array<IThread>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch("/api/v1/profile/last-read-threads", {
      headers,
    });
    const data = await response.json();

    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return (data as Array<ThreadDTO>).map(ProfileService.fromThreadDTO);
  }

  private static fromThreadDTO(thread: ThreadDTO): IThread {
    return {
      idHash: thread.thread.thread_id,
      subject: thread.thread.starting_email.subject,
      content: thread.thread.starting_email.content,
      htmlContent: thread.thread.starting_email.html_content,
      sender: {
        mailmanId: thread.thread.starting_email.sender.mailman_id,
        name: thread.thread.starting_email.sender.name,
        imageUrl: thread.thread.starting_email.sender.image_url,
      },
      date: thread.thread.starting_email.date,
      dateActive: thread.thread.date_active,
      likes: thread.thread.starting_email.get_votes.likes,
      dislikes: thread.thread.starting_email.get_votes.dislikes,
      mailingList: {
        name: thread.thread.starting_email.mailinglist.name,
        displayName: thread.thread.starting_email.mailinglist.display_name,
      },
      participants: thread.thread.participants.map((participant) => ({
        mailmanId: participant.mailman_id,
        name: participant.name,
        imageUrl: participant.image_url,
      })),
      participantsCount: thread.thread.participants_count,
      emailsCount: thread.thread.emails_count,
      isThread: true,
      threadId: thread.thread.thread_id,
      flaggedByModerator: thread.thread.starting_email.flagged_by_moderator,
    };
  }

  public async getLastVotedPosts(): Promise<Array<IMessage>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch("/api/v1/profile/votes", { headers });
    const data = await response.json();

    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return (data as Array<VoteDTO>).map(ProfileService.fromVoteDTO);
  }

  private static fromVoteDTO(vote: VoteDTO): IMessage {
    return {
      idHash: vote.email.message_id_hash,
      subject: vote.email.subject,
      content: vote.email.content,
      htmlContent: vote.email.html_content,
      sender: {
        mailmanId: vote.email.sender.mailman_id,
        name: vote.email.sender.name,
        imageUrl: vote.email.sender.image_url,
      },
      date: vote.email.date,
      likes: vote.email.get_votes.likes,
      dislikes: vote.email.get_votes.dislikes,
      mailingList: {
        name: vote.email.mailinglist.name,
        displayName: vote.email.mailinglist.display_name,
      },
      threadId: vote.email.thread_id,
      flaggedByModerator: vote.email.flagged_by_moderator,
    };
  }

  public async deleteAccount(): Promise<void> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };

    const csrfToken = this.securityService?.getToken();
    if (csrfToken) {
      headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;
    }

    const response = await fetch("/api/v1/profile/account", {
      method: "DELETE",
      headers,
    });

    if (response.status !== 204) {
      throw Error(DEFAULT_ERROR_MSG);
    }
  }

  public getTimeZone(): Observable<ITimeZone | undefined> {
    this.timeZoneRequestQueue$.next();
    return this.timeZone$.asObservable();
  }

  private scheduleTimeZoneRequest(): void {
    this.timeZoneRequestQueue$
      .asObservable()
      .pipe<void, TimeZoneDTO>(
        take(1),
        mergeMap(() => ajax.getJSON<TimeZoneDTO>("/api/v1/profile/time-zone"))
      )
      .subscribe((timeZoneDTO) => {
        this.timeZone$.next(ProfileService.toITimeZone(timeZoneDTO));
      });
  }

  public async saveTimeZone(timeZone: ITimeZone): Promise<void> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };

    const csrfToken = this.securityService?.getToken();
    if (csrfToken) {
      headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;
    }

    const response = await fetch("/api/v1/profile/time-zone", {
      method: "PUT",
      headers,
      body: JSON.stringify(ProfileService.toTimeZoneDTO(timeZone)),
    });

    const data = await response.json();

    const error = await FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    this.timeZone$.next(ProfileService.toITimeZone(data as TimeZoneDTO));
  }

  private static toITimeZone(timeZoneDTO: TimeZoneDTO): ITimeZone {
    return {
      timeZone: timeZoneDTO.timezone,
    };
  }

  private static toTimeZoneDTO(timeZone: ITimeZone): TimeZoneDTO {
    return {
      timezone: timeZone.timeZone,
    };
  }
}
