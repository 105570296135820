<template>
  <div class="paginator mt-3 mt-md-5">
    <div
      class="d-block d-md-flex justify-content-center justify-content-md-between"
    >
      <div class="d-none d-md-flex align-items-center">
        <span>Show</span>
        <select
          name="count"
          class="form-control input-sm mx-2"
          @change="changePage(page, limit)"
          v-model="limit"
        >
          <option
            v-if="!perPageOptions.includes(limit)"
            :value="limit"
            selected
          >
            {{ limit }}
          </option>
          <option v-if="!perPageOptions.includes(limit)" disabled>---</option>
          <option
            :value="perPage"
            v-for="(perPage, key) in perPageOptions"
            :key="`pagination-view-per-page-options-${key}`"
            :selected="perPage == limit"
          >
            {{ perPage }}
          </option>
        </select>
        <span>entries</span>
      </div>
      <div v-if="hasNext || hasPrevious">
        <div class="text-center d-none d-md-block">
          <span class="small">
            {{ displayPaginationInformation(page) }}
          </span>
        </div>
        <nav aria-label="Page navigation">
          <ul class="pagination">
            <li v-if="hasPrevious" class="page-item">
              <a @click="changePage(page - 1, limit)" class="page-link">
                {{ labelPrevious }}
              </a>
            </li>
            <li v-else class="page-item disabled">
              <a href="#" class="page-link">{{ labelPrevious }}</a>
            </li>

            <li
              class="page-item"
              :class="{ active: pageNum === page }"
              v-for="(pageNum, key) in pageRangeEllipses"
              :key="`pagination-view-page-num-${key}`"
            >
              <span v-if="pageNum === page" class="page-link">
                {{ pageNum }}
              </span>
              <a
                v-else-if="pageNum === '...'"
                href="#"
                class="jump-to-page active page-link"
              >
                ...
              </a>
              <a v-else @click="changePage(pageNum, limit)" class="page-link">
                {{ pageNum }}
              </a>
            </li>

            <li v-if="hasNext" class="page-item">
              <a @click="changePage(page + 1, limit)" class="page-link">
                {{ labelNext }}
              </a>
            </li>
            <li v-else class="page-item disabled">
              <a href="#" class="page-link">
                {{ labelNext }}
              </a>
            </li>
          </ul>
        </nav>
        <div class="d-md-none">
          <span>
            {{ displayPaginationInformation(page) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PaginationView extends Vue {
  @Prop({ required: true }) public totalCount!: number;
  @Prop({ default: false }) public byDate!: boolean;
  public maxPageRange = 10;
  public perPageOptions = [10, 25, 50, 100, 200];
  public page = 1;
  public limit = 10;

  private mounted(): void {
    this.limit = this.perPageOptions[0];
    this.page = 1;
  }

  get labelPrevious() {
    if (this.byDate) {
      return "Newer";
    } else {
      return "Previous";
    }
  }

  get labelNext() {
    if (this.byDate) {
      return "Older";
    } else {
      return "Next";
    }
  }

  get hasPrevious() {
    return this.page > 1 && this.numPages > 1;
  }

  get hasNext() {
    return this.page * this.limit < this.totalCount;
  }

  get numPages() {
    return Math.ceil(this.totalCount / this.limit);
  }

  get pageRangeEllipses() {
    const _range = (start: number, end: number): Array<number> => {
      const tmpArr = [];
      for (let i = start; i <= end; i++) {
        tmpArr.push(i);
      }

      return tmpArr;
    };

    let rangeArr: Array<number | string> = [];
    if (this.numPages > this.maxPageRange) {
      rangeArr.push(1);
      let subrangeLower = this.page - (this.maxPageRange / 2 - 2);

      if (subrangeLower > 3) {
        rangeArr.push("...");
      } else {
        subrangeLower = 2;
      }

      rangeArr = [...rangeArr, ..._range(subrangeLower, this.page)];

      if (this.page !== 1 && this.page !== this.numPages) {
        rangeArr.push(this.page);
      }

      let subrangeUpper = this.page + (this.maxPageRange / 2 - 2);

      if (subrangeUpper >= this.numPages - 2) {
        subrangeUpper = this.numPages - 1;
      }

      rangeArr = [...rangeArr, ..._range(this.page + 1, subrangeUpper + 1)];

      if (subrangeUpper < this.numPages - 2) {
        rangeArr.push("...");
      }

      rangeArr.push(this.numPages);
    } else {
      rangeArr = _range(1, this.numPages);
    }
    return rangeArr;
  }

  public displayPaginationInformation(pageNumber: number) {
    const startCount = (pageNumber - 1) * this.limit + 1;
    const endCount =
      pageNumber * this.limit < this.totalCount
        ? pageNumber * this.limit
        : this.totalCount;
    return `Showing ${startCount} to ${endCount} out of ${this.totalCount} entries`;
  }

  public changePage(pageNumber: number, limit: number) {
    if (pageNumber && limit) {
      this.$emit("onPageChange", { pageNumber, limit });
      this.page = pageNumber;
    }
  }
}
</script>
