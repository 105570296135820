<template>
  <footer class="app-footer">
    <div class="d-flex flex-wrap justify-content-between">
      <div class="flex-wrap text-center">
        <span>© {{ currentYear }}&nbsp;APNIC</span>
        <span class="ml-3">ABN&nbsp;42&nbsp;081&nbsp;528&nbsp;010</span>
      </div>
      <ul class="d-flex flex-wrap justify-content-center">
        <li
          v-for="(asideLink, index) in footerAsideLinks"
          :key="`aside-link-${index}`"
        >
          <a :href="asideLink.link">{{ asideLink.label_en_us }}</a>
        </li>
      </ul>
      <ul class="d-flex flex-wrap justify-content-end">
        <li class="pr-3">Connect with us</li>
        <li
          v-for="(socialLink, index) in footerSocialLinks"
          :key="`social-link-${index}`"
        >
          <a :href="socialLink.link" :class="socialLink.iconClass">
            <span class="sr-only">{{ socialLink.label_en_us }}</span>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import moment from "moment";

export type ThemeLink = {
  label_en_us?: string;
  link?: string;
  iconClass?: string;
  isLogout?: boolean;
};

export type ThemeLinks = {
  footerAsideLinks?: ThemeLink[];
  footerLogo?: ThemeLink;
  footerSocialLinks?: ThemeLink[];
  omnibarCurrentUserAccountLinks?: ThemeLink[];
  omnibarCurrentUserMembershipLinks?: ThemeLink[];
  omnibarLogo?: ThemeLink;
  omnibarQuickLinks?: ThemeLink[];
};

@Component
export default class FooterView extends Vue {
  private currentYear = moment().year();
  private themeData = {} as ThemeLinks;

  get footerSocialLinks(): ThemeLink[] {
    return this.themeData.footerSocialLinks || [];
  }

  get footerAsideLinks(): ThemeLink[] {
    return this.themeData.footerAsideLinks || [];
  }

  public async fetchThemeData(): Promise<void> {
    const WEB_FRAMEWORK_URL_PROD = "https://webdist.data.apnic.net/lists";
    const jsonVer = "theme-v1.json";
    const url = `${WEB_FRAMEWORK_URL_PROD}/${jsonVer}`;
    const response = await fetch(url);
    if (response.status === 200) {
      this.themeData = await response.json();

      // Update base.html Omnibar quick links
      const quickLinkWrapper = document.querySelector(
        ".omnibar-quick-links__wrap"
      );
      if (quickLinkWrapper && this.themeData.omnibarQuickLinks) {
        quickLinkWrapper.innerHTML = this.themeData.omnibarQuickLinks
          ?.map(
            (quickLink) =>
              `<a class="dropdown-item omnibar-quick-links__link" href="${quickLink.link}">${quickLink.label_en_us}</a>`
          )
          .join("");
      }
    }
  }

  beforeMount(): void {
    this.fetchThemeData();
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
#wrapper {
  .app-footer {
    position: fixed;
    right: 0;
    /* subtracted the whole width of the sidebar */
    width: calc(100% - 60px);

    @media (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }

  &.toggled {
    .app-footer {
      width: calc(100% - 300px);
    }
  }
}
</style>
