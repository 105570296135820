<template>
  <div class="news-feed w-100 d-flex flex-column align-items-center">
    <div class="welcome-banner">
      <img
        class="img-fluid"
        src="@/assets/img/orbit-newsfeed-banner-1280x200px-01d.png"
      />
      <h2 class="my-3">Welcome to Orbit</h2>
      <p>
        A place for the APNIC community to connect, discuss and share
        information related to Internet addressing and networking. Orbit is an
        evolution of mailing lists, facilitated by APNIC on behalf of the
        community. Please be mindful that the
        <a
          href="https://www.apnic.net/community/participate/mailinglists/code-of-conduct/"
          >Code of Conduct</a
        >
        applies.
      </p>
    </div>

    <div
      class="news-feed__header d-none d-md-flex w-100 justify-content-end mb-3"
    >
      <NewsFeedViewSwitcher v-model="viewOption" />
    </div>
    <ul class="w-100 p-0">
      <li class="mb-2" v-for="(thread, key) in threads" :key="`thread_${key}`">
        <Message
          :message="thread"
          :messageStyle="messageStyle"
          :expanded="expanded"
          :voteEnabled="user && user.id !== thread.sender.mailmanId"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Vue } from "vue-property-decorator";
import NewsFeedViewSwitcher, {
  ViewOptions,
} from "@/components/NewsFeedViewSwitcher.vue";
import IThreadService from "@/application/IThreadService";
import ISecurityService from "@/application/ISecurityService";
import IThread from "@/domain/IThread";
import Message, { MessageStyle } from "@/components/Message.vue";
import {
  asyncScheduler,
  concatMap,
  filter,
  fromEvent,
  map,
  scan,
  startWith,
  takeWhile,
  throttleTime,
} from "rxjs";
import IUser from "@/domain/IUser";

const COLLAPSED_MESSAGE_HEIGHT = 132;
const WINDOW_HEIGHT_SKEW = 264;
const MOBILE_SIZE_BREAK_POINT = 768;

@Component({
  components: { Message, NewsFeedViewSwitcher },
})
export default class NewsFeedView extends Vue {
  @Inject("IThreadService") threadService!: IThreadService;
  @Inject("ISecurityService") securityService!: ISecurityService;
  private viewOption = ViewOptions.EXPANDED;
  private threads: Array<IThread> = [];
  private messageStyle = MessageStyle.BOX_SHADOW;
  private user: IUser | null = null;
  private mobile = false;
  private get expanded(): boolean {
    return this.viewOption === ViewOptions.EXPANDED && !this.mobile;
  }

  private mounted(): void {
    this.scheduleNewsFeedInfiniteScroll();
    this.securityService.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });

    this.scheduleHandlingOfWindowSizeChange();
  }

  private scheduleHandlingOfWindowSizeChange(): void {
    const windowWidth$ = fromEvent(window, "resize").pipe(
      throttleTime(500, asyncScheduler, { trailing: true }),
      map(() => window.innerWidth),
      startWith(window.innerWidth)
    );

    windowWidth$.subscribe((width) => {
      this.mobile = width < MOBILE_SIZE_BREAK_POINT;
    });
  }

  private scheduleNewsFeedInfiniteScroll(): void {
    const limit = Math.ceil(
      (window.innerHeight - WINDOW_HEIGHT_SKEW) / COLLAPSED_MESSAGE_HEIGHT
    );

    const bottomOfWindow$ = fromEvent(window, "scroll").pipe(
      filter(NewsFeedView.isBottomOfWindow)
    );

    const offset$ = bottomOfWindow$.pipe(
      scan((offset) => offset + limit, 0),
      startWith(0)
    );

    const moreThreads$ = offset$.pipe(
      concatMap((offset) => this.threadService.getLastThreads(limit, offset)),
      takeWhile((moreThreads) => moreThreads.length > 0)
    );

    moreThreads$.subscribe((moreThreads) => {
      this.threads = [...this.threads, ...moreThreads];
    });
  }

  private static isBottomOfWindow(event: Event): boolean {
    const document = event.target as Document;
    const window = event.currentTarget as Window;

    return NewsFeedView.isAlmostEqual(
      document.documentElement.scrollTop + window.innerHeight,
      document.documentElement.offsetHeight
    );
  }

  private static isAlmostEqual(a: number, b: number): boolean {
    return Math.abs(a - b) < 1;
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
h1 {
  font-size: $font-size-section-title;
  color: $blue-section-title;
}
.news-feed {
  &__header {
    max-width: $section-max-width;
  }

  ul {
    max-width: $section-max-width;
    list-style-type: none;
  }

  .welcome-banner {
    max-width: $section-max-width;
    font-size: 18px;
  }
}
</style>
