<template>
  <div class="contextual__navbar-mobile">
    <div class="dropdown contextual__dropdown">
      <a
        ref="hamburgerButton"
        :class="clazz"
        href="#"
        role="button"
        id="mobileContextualMenu"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        @click.prevent="openMobileMenu"
      >
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Vue } from "vue-property-decorator";
import { IMobileMenuService } from "@/application/IMobileMenuService";

@Component
export default class MobileNavbarView extends Vue {
  @Inject("IMobileMenuService") mobileMenuService!: IMobileMenuService;

  private clazz = [
    "btn",
    "btn-secondary",
    "dropdown-toggle",
    "omnibar-contextual-hamburger__toggle",
  ];

  private openMobileMenu(): void {
    this.mobileMenuService.pushOpenRequest();
  }
}
</script>

<style lang="scss" scoped>
.omnibar-contextual-hamburger__toggle {
  &.selected {
    background-color: initial !important;
  }

  &::before {
    content: "" !important;
  }
}
</style>
