<template>
  <section class="content-section d-flex flex-column flex-md-row mb-5">
    <template v-if="profile">
      <div class="profile-details__left flex-fill d-flex flex-column w-50">
        <div class="d-flex">
          <h1 class="flex-fill">My Details</h1>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-column flex-md-row mb-4">
            <div
              class="flex-fill profile-details__label text-left text-md-right mr-3"
            >
              Display Name
            </div>
            <div>{{ profile.displayName }}</div>
          </div>
          <div class="d-flex flex-column flex-md-row mb-4">
            <div
              class="flex-fill profile-details__label text-left text-md-right mr-3"
            >
              Full Name
            </div>
            <div>{{ profile.fullName }}</div>
          </div>
          <div
            class="d-flex flex-column flex-md-row mb-4"
            v-for="(item, key) in additionalInformationItems"
            :key="`ai_${key}`"
          >
            <div
              class="flex-fill profile-details__label text-left text-md-right mr-3"
            >
              {{ item.label }}
            </div>
            <div v-if="isWebLink(item.value)">
              <a :href="item.value" target="_blank">{{ item.value }}</a>
            </div>
            <div v-else>
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <div class="profile-details__right flex-fill w-50">
        <div class="d-flex flex-column">
          <div class="d-flex flex-column flex-md-row mb-4">
            <div
              class="profile-details__label text-left text-md-right mr-3 w-100 w-md-25"
            >
              About me
            </div>
            <!-- aboutMeHtml uses DOMPurify to make this safe -->
            <!-- nosemgrep avoid-v-html -->
            <div class="w-100 w-md-75" v-html="aboutMeHtml"></div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="w-100 p-0 p-md-2">
        <LoadingSkeleton width="100%" height="375px" radius="0" />
      </div>
    </template>
  </section>
</template>

<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import IProfile from "@/domain/IProfile";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import { ProfileDetailsMixIn } from "@/components/ProfileDetailsMixIn";
import SanitizeHtmlContentService from "@/infrastructure/SanitizeHtmlContentService";

@Component({
  components: { LoadingSkeleton },
})
export default class ProfileDetails extends mixins(ProfileDetailsMixIn) {
  private aboutMe = "";

  get aboutMeHtml() {
    return SanitizeHtmlContentService.sanitizeHtmlContent(
      this.aboutMe
        .split(/[\r\n|\r|\n]+/)
        .map((paragraph) => `<p>${paragraph}</p>`)
        .join("")
    );
  }

  @Watch("profile")
  private onProfileChanged(profile: IProfile) {
    this.aboutMe = profile?.aboutMe ?? "";
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_variables.scss";
@import "../assets/scss/_base.scss";

.content-section {
  h1 {
    font-size: 1.1rem;
    font-weight: normal;
  }

  .fa {
    transform: scale(0.8);
  }

  a {
    cursor: pointer;
  }

  .profile-details {
    &__label {
      max-width: 110px;
      font-weight: 600;
      text-transform: capitalize;
    }

    &__right {
      .textarea-wordcount {
        width: 100%;
        height: 20rem;
        textarea {
          width: 100%;
          height: 19rem;
          border: 1px solid $grey-border;
          padding: 1rem 1rem 1.5rem 1rem;

          &:focus {
            outline: none;
          }
        }

        &__count {
          position: relative;
          margin-top: -2rem;
          margin-right: 1rem;
          text-align: right;
          color: var(--gray);
          user-select: none;
          background-color: white;
          margin-left: 1rem;
          padding-bottom: 0.2rem;

          @media (min-width: $breakpoint-md) {
            padding-bottom: 0.25rem;
          }
        }
      }

      button {
        &:disabled {
          background-color: var(--gray);
          border-color: var(--gray);
        }
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    h1 {
      font-size: 1.4rem;
    }

    .profile-details__left {
      width: 50%;
      max-width: 50%;
      border-right: 1px solid $grey-border;
    }
  }
}
</style>
