<template>
  <div class="table-responsive">
    <h1>
      {{ title }}
    </h1>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Display Name</th>
          <th scope="col">Description</th>
          <th scope="col">Month</th>
          <th scope="col">Year</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="metric in data" :key="metric.name">
          <td>{{ metric.name }}</td>
          <td>{{ metric.displayName }}</td>
          <td>{{ metric.description }}</td>
          <td>{{ getMonthName(metric.month) }}</td>
          <td>{{ metric.year }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-property-decorator";
import { IMailingListCreatedMetric } from "@/domain/IMailingListCreatedMetric";
import { PropType } from "vue";

export default Vue.extend({
  name: "NewMailingListsMetrics",
  props: {
    data: {
      type: Array as PropType<Array<IMailingListCreatedMetric>>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {
    getMonthName(month: number): string {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthNames[month - 1];
    },
  },
});
</script>

<style scoped lang="scss">
h1 {
  font-size: 1.1rem;
  text-align: center;
  color: rgb(0, 123, 189);
}
</style>
