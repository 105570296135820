import { render, staticRenderFns } from "./ProfileTimeZonePreferenceView.vue?vue&type=template&id=35a5d987&scoped=true"
import script from "./ProfileTimeZonePreferenceView.vue?vue&type=script&lang=ts"
export * from "./ProfileTimeZonePreferenceView.vue?vue&type=script&lang=ts"
import style0 from "./ProfileTimeZonePreferenceView.vue?vue&type=style&index=0&id=35a5d987&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_prettier@2.6.2_vue-template-compiler@2.6.14_webpack@5.91.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35a5d987",
  null
  
)

export default component.exports