import { inject, singleton } from "tsyringe";
import ISecurityService from "@/application/ISecurityService";
import IVoteService from "@/application/IVoteService";
import IVote from "@/domain/IVote";

const DEFAULT_HTTP_HEADERS = {
  "Content-Type": "application/json",
  "X-CSRFToken": "",
};

@singleton()
export default class VoteService implements IVoteService {
  constructor(
    @inject("ISecurityService") private securityService: ISecurityService
  ) {}

  async get_vote_info(messageIdHash: string): Promise<number | undefined> {
    const response = await fetch(
      `/api/v1/profile/votes?message_id_hash=${messageIdHash}`
    );

    return (await response.json())["value"];
  }

  async vote(
    mailingList: string,
    messageIdHash: string,
    vote: number
  ): Promise<IVote> {
    const headers = DEFAULT_HTTP_HEADERS;
    const csrfToken = this.securityService.getToken();

    if (csrfToken) {
      headers["X-CSRFToken"] = csrfToken;
    }

    const response = await fetch(`/api/v1/profile/votes`, {
      method: "POST",
      body: JSON.stringify({
        mlist_fqdn: mailingList,
        message_id_hash: messageIdHash,
        vote,
      }),
      headers,
    });

    const data = await response.json();

    return data;
  }
}
