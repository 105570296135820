import ISidebarStateService from "@/application/ISidebarStateService";
import { singleton } from "tsyringe";
import { Observable, BehaviorSubject } from "rxjs";

@singleton()
export default class SidebarStateService implements ISidebarStateService {
  private collapse$ = new BehaviorSubject(false);

  constructor() {
    //remove the sidebar theme state so that it will not interfere
    if (localStorage.getItem("WebFrameworkThemeSidebarState")) {
      localStorage.removeItem("WebFrameworkThemeSidebarState");
    }
    const collapse = sessionStorage.getItem("collapse") === "true";
    this.collapse$.next(collapse);
  }
  getCollapse(): Observable<boolean> {
    return this.collapse$.asObservable();
  }

  setCollapse(collapse: boolean): void {
    this.collapse$.next(collapse);
    sessionStorage.setItem("collapse", JSON.stringify(collapse));
  }
}
