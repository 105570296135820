<template>
  <div>
    <div class="mt-3 flex">
      <span class="page-header">Statistics & Metrics</span>
    </div>
    <div class="mt-3 p-3">
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="content-section mb-2">
            <label class="mr-1">Time range:</label>
            <select
              class="form-control"
              @change.prevent=""
              v-model="timeRangeOption"
            >
              <option
                v-for="(option, key) in timeRangeOptions"
                :value="option.range"
                :key="`time-range-option-${key}`"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="list-group">
            <button
              v-for="option in metricsOptions"
              :key="option.key"
              :class="[
                'list-group-item',
                'list-group-item-action',
                { active: metricsKey === option.key },
              ]"
              aria-current="true"
              @click="changeMetricsOption(option.key)"
            >
              {{ option.label }}
            </button>
          </div>
        </div>
        <div class="col-12 col-md-9 card p-4 mt-3 mt-md-0">
          <LoadingSkeleton v-if="loading" width="100%" height="375px" />
          <template v-else>
            <NewMailingListsMetrics
              v-if="metricsKey === 'newMailingLists'"
              :data="mailingListsMetricsData"
              :title="chartOptions.title"
            />
            <MetricsChart v-else :chartOptions="chartOptions"></MetricsChart>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import NewMailingListsMetrics from "@/components/NewMailingListsMetrics.vue";
import Vue_, { VueConstructor } from "vue";
import IMetricsService from "@/application/IMetricsService";
import moment, { DurationInputArg2, Moment } from "moment";
import { DurationInputArg1 } from "moment/moment";
import { IMetric } from "@/domain/IMetric";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import { IMailingListCreatedMetric } from "@/domain/IMailingListCreatedMetric";
import MetricsChart, {
  MetricsChartOptions,
} from "@/components/MetricsChart.vue";

type MetricKey =
  | "allNewUsers"
  | "newOrbitUsers"
  | "postsThroughOrbit"
  | "newMailingLists"
  | "newPostsExcludeBgpPrivate"
  | "newPostsExcludeBgp";

type MetricOption = {
  key: MetricKey;
  label: string;
  titlePrefix: string;
  yAxisLabel?: string;
  seriesLabel?: string;
};

type TimeRangeOption = { label: string; range: string };

const METRIC_OPTIONS: Array<MetricOption> = [
  {
    key: "newOrbitUsers",
    label: "New Orbit Users",
    titlePrefix: "New Orbit Users",
    yAxisLabel: "Number of Users",
    seriesLabel: "New Users",
  },
  {
    key: "postsThroughOrbit",
    label: "Posts Through Orbit",
    titlePrefix: "Posts Through Orbit",
    yAxisLabel: "Number of Posts",
    seriesLabel: "Post Through Orbit",
  },
  {
    key: "allNewUsers",
    label: "All New Users",
    titlePrefix: "New Users",
    yAxisLabel: "Number of Users",
    seriesLabel: "New Users",
  },
  {
    key: "newMailingLists",
    label: "New Mailing Lists",
    titlePrefix: "Mailing Lists Created",
    yAxisLabel: "",
    seriesLabel: "",
  },
  {
    key: "newPostsExcludeBgpPrivate",
    label: "Number of Post (Exclude BGP Stats and Private List)",
    titlePrefix: "Number of Posts (Hidden & BGP removed)",
    yAxisLabel: "Number of Posts",
    seriesLabel: "Number of Post(Exclude BGP and Private List)",
  },
  {
    key: "newPostsExcludeBgp",
    label: "Number of Post (Exclude BGP Stats)",
    titlePrefix: "Number of Posts (BGP removed)",
    yAxisLabel: "Number of Posts",
    seriesLabel: "Number of Post(Exclude BGP Stats)",
  },
];

const TIME_RANGE_OPTIONS: Array<TimeRangeOption> = [
  {
    label: "Last 30 days",
    range: "30d",
  },
  { label: "Last 60 days", range: "60d" },
  { label: "Last 90 days", range: "90d" },
  { label: "Last 6 months", range: "6M" },
  { label: "Last 1 year", range: "12M" },
  { label: "Last 1.5 years", range: "18M" },
];

type Data = {
  metricsKey: MetricKey;
  metricsOptions: Array<MetricOption>;
  timeRangeOption: string;
  timeRangeOptions: Array<TimeRangeOption>;
  metricsData: Array<IMetric>;
  mailingListsMetricsData: Array<IMailingListCreatedMetric>;
  loading: boolean;
};

interface InjectedKeys {
  metricsService: IMetricsService;
}

type MetricsQuery = { metric: MetricKey; from: Moment };

const Vue = Vue_ as VueConstructor<Vue_ & InjectedKeys>;

export default Vue.extend({
  name: "MetricsView",
  components: {
    NewMailingListsMetrics,
    LoadingSkeleton,
    MetricsChart,
  },
  inject: {
    metricsService: { from: "IMetricsService" },
  },
  data(): Data {
    return {
      metricsOptions: [...METRIC_OPTIONS],
      metricsKey: "newOrbitUsers",
      timeRangeOptions: [...TIME_RANGE_OPTIONS],
      timeRangeOption: TIME_RANGE_OPTIONS[5].range,
      metricsData: [],
      mailingListsMetricsData: [],
      loading: false,
    };
  },
  computed: {
    metricsQuery(): MetricsQuery {
      return {
        metric: this.metricsKey,
        from: this.toMoment(this.timeRangeOption),
      };
    },
    chartOptions(): MetricsChartOptions {
      const option = METRIC_OPTIONS.find(
        (option) => option.key === this.metricsKey
      );

      return {
        data: this.metricsData,
        title: `${option?.titlePrefix} from ${this.metricsQuery.from.format(
          "DD/MM/YYYY"
        )} to ${moment().format("DD/MM/YYYY")}`,
        yAxisLabel: option?.yAxisLabel ?? "",
        seriesLabel: option?.seriesLabel ?? "",
      };
    },
  },
  methods: {
    changeMetricsOption(metricsKey: MetricKey): void {
      this.metricsKey = metricsKey;
    },
    toMoment(timeRangeOption: string): Moment {
      const now = moment();
      const match = timeRangeOption.match(/([0-9]+)([dMy])/);

      if (match === null) {
        throw new Error("Invalid time range option selection");
      }

      return now.subtract(
        parseInt(match[1]) as DurationInputArg1,
        match[2] as DurationInputArg2
      );
    },
  },
  watch: {
    metricsQuery: {
      immediate: true,
      async handler(newValue: MetricsQuery): Promise<void> {
        this.loading = true;
        switch (newValue.metric) {
          case "allNewUsers":
            this.metricsData = await this.metricsService.getAllNewUsersMetrics(
              newValue.from.toDate()
            );
            break;
          case "newOrbitUsers":
            this.metricsData =
              await this.metricsService.getNewOrbitUsersMetrics(
                newValue.from.toDate()
              );
            break;
          case "postsThroughOrbit":
            this.metricsData =
              await this.metricsService.getPostsThroughOrbitMetrics(
                newValue.from.toDate()
              );
            break;
          case "newMailingLists":
            this.mailingListsMetricsData =
              await this.metricsService.getNewMailingListsMetrics(
                newValue.from.toDate()
              );
            break;
          case "newPostsExcludeBgpPrivate":
            this.metricsData = await this.metricsService.getNewPostsMetrics(
              ["bgp-stats.lists.apnic.net"],
              true,
              newValue.from.toDate()
            );
            break;
          case "newPostsExcludeBgp":
            this.metricsData = await this.metricsService.getNewPostsMetrics(
              ["bgp-stats.lists.apnic.net"],
              false,
              newValue.from.toDate()
            );
            break;
        }
        this.loading = false;
      },
    },
  },
});
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.page-header {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 9px;
  font-size: 175%;
}
</style>
