import { render, staticRenderFns } from "./ThreadParticipantsView.vue?vue&type=template&id=0112a50d"
import script from "./ThreadParticipantsView.vue?vue&type=script&lang=ts"
export * from "./ThreadParticipantsView.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_prettier@2.6.2_vue-template-compiler@2.6.14_webpack@5.91.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports