<template>
  <div>
    <div v-if="reportedMessageList.length > 0">
      <div class="row mb-3">
        <div class="col-md-12">Perform action on selected messages</div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Subject</th>
              <th scope="col">Reporter</th>
              <th scope="col">Reason</th>
              <th scope="col">Report Date</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(group, subject) in groupedReportedMessage">
              <tr v-for="(item, index) in group" :key="item.id">
                <td
                  class="table-subject"
                  :rowspan="group.length"
                  v-if="index == 0"
                >
                  <a href="#">
                    <button
                      class="subject-btn"
                      type="button"
                      @click="
                        onShowModal(subject, item.messageIdHash, item.threadId)
                      "
                    >
                      {{ subject }}
                    </button></a
                  >
                </td>
                <td>{{ item.reporterEmail }}</td>
                <td>{{ item.reason }}</td>
                <td>{{ getReportMessageDate(item.createdAt) }}</td>
              </tr>
            </template>
          </tbody>
        </table>

        <ReportPostModeratorDecisionModal
          v-if="showModal"
          :subject="subject"
          :m-list="mList"
          :thread-id="threadId"
          :message-id-hash="messageIdHash"
          :page="this.page"
          :limit="this.limit"
          @showModal="onShowModal"
          @onCancel="onCancel"
          @onEmailTemplateSelected="onEmailTemplateSelected"
        />
      </div>
      <PaginationView :total-count="totalCount" @onPageChange="onPageChange" />
    </div>
    <div v-else>There are currently no reported messages.</div>
    <ReportPostMessageCounter
      class="d-none"
      :m-list="mList"
      :email-count="reportedMessageList.length"
    />
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import PaginationView from "@/views/PaginationView.vue";
import IMessageReportService from "@/application/IMessageReportService";
import IMessageService from "@/application/IMessageService";
import IMessageReport from "@/domain/IMessageReport";

import ReportPostModeratorDecisionModal from "@/components/ReportPostModeratorDecisionModal.vue";
import ReportPostMessageCounter from "@/components/ReportPostMessageCounter.vue";

import moment from "moment";

type CustomMessageReport = {
  id: number;
  messageIdHash: string;
  messageSubject: string;
  reason: string;
  reporterEmail: string;
  createdAt: string;
  threadId: string;
};

@Component({
  components: {
    PaginationView,
    ReportPostModeratorDecisionModal,
    ReportPostMessageCounter,
  },
})
export default class ReportPostModeratorView extends Vue {
  @Inject("IMessageReportService") reportMessageService!: IMessageReportService;
  @Inject("IMessageService") messageService!: IMessageService;
  @Prop({ required: true }) mList!: string;
  private limit = 10;
  private totalCount!: number;
  private page = 1;
  private status = "reported";
  private showModal = false;
  private subject!: string;
  private messageIdHash!: string;
  private threadId!: string;
  private results!: CustomMessageReport;
  private reportMessages: IMessageReport[] = [];
  async mounted() {
    this.reportMessageService
      .getMessageReports()
      .subscribe((messageReports) => {
        this.reportMessages = messageReports.items;
        this.totalCount = messageReports.count;
      });
    await this.reportMessageService.fetchMessageReports(
      this.mList,
      this.limit,
      this.page,
      this.status
    );
  }

  private get reportedMessageList(): IMessageReport[] {
    return this.reportMessages;
  }

  // group the message by subject
  private get groupedReportedMessage(): {
    [messageSubject: string]: CustomMessageReport[];
  } {
    return this.reportedMessageList.reduce(
      (
        messageSubject: { [key: string]: CustomMessageReport[] },
        item: CustomMessageReport
      ) => {
        if (!messageSubject[item.messageSubject]) {
          messageSubject[item.messageSubject] = [];
        }
        messageSubject[item.messageSubject].push(item);
        return messageSubject;
      },
      {}
    );
  }

  private getReportMessageDate(date: string): string {
    return moment.parseZone(date).format("D MMM YYYY h:mm a");
  }

  private onShowModal(
    subject: string,
    messageIdHash: string,
    threadId: string
  ) {
    this.showModal = true;
    this.subject = subject;
    this.messageIdHash = messageIdHash;
    this.threadId = threadId;
  }

  onCancel() {
    this.showModal = false;
  }

  private async onPageChange(data: { pageNumber: number; limit: number }) {
    this.page = data.pageNumber;
    this.limit = data.limit;
    await this.reportMessageService.fetchMessageReports(
      this.mList,
      this.limit,
      this.page,
      this.status
    );
  }

  async onEmailTemplateSelected() {
    await this.reportMessageService.fetchMessageReports(
      this.mList,
      this.limit,
      this.page,
      this.status
    );
  }
}
</script>

<style scoped lang="scss">
.report-btn:hover {
  background-color: #b00020;
  color: white;
}

.subject-btn {
  color: #007bbd;
  padding: 0;
  background: none;
  border: none;
}
</style>
