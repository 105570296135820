<template>
  <div class="editor">
    <editor
      :api-key="config.editorApiKey"
      :init="editorSettings"
      v-model="content"
      ref="editor"
    />
    <div
      class="attachment-list-container"
      v-for="(file, index) in internalFiles"
      :key="file.file.name"
      :id="'container' + index"
    >
      <div class="progress" :class="file.clazz">
        <div class="progress-label">
          <div style="margin-left: 12px">
            <span class="progress-icon" :class="file.iconClazz"
              ><i class="fa fa-check-circle mr-2"></i
            ></span>
            <span
              v-if="file.uploadProgress === 100 && !file.uploadError"
              class="mr-3 file-name-success"
              >{{ file.file.name }}</span
            >
            <span v-else-if="!file.uploadError" class="mr-3 file-name">{{
              file.file.name
            }}</span>
            <span
              v-else-if="file.uploadError && file.uploadProgress === 100"
              class="mr-3 file-name-error"
              >{{ file.file.name }}</span
            >
            <span
              v-if="file.uploadProgress === 100 && !file.uploadError"
              class="total-file-size-success"
              >{{ attachmentFileSize(file.file.size) }}</span
            >
            <span v-else-if="!file.uploadError" class="total-file-size">{{
              attachmentFileSize(file.file.size)
            }}</span>
            <span
              v-else-if="file.uploadError && file.uploadProgress === 100"
              class="total-file-size-error"
              >{{ attachmentFileSize(file.file.size) }}</span
            >
            <span
              v-if="!file.uploadError"
              class="progress-bar-upload-progress"
              :class="file.iconClazz"
            >
              - {{ file.uploadProgress }}% uploaded
            </span>
            <span v-else class="progress-bar-upload-progress-error">
              - Upload failed
            </span>
          </div>
          <div class="progress-close">
            <button
              class="file-btn-close"
              type="button"
              @click="onRemoveFile(file)"
            >
              <img
                src="../assets/img/wysiwyg/x-close.png"
                class="file-icon-close"
                alt="x-button"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <file-max-alert :show="showModal" @close="showModal = false">
      <div class="icon-modal">
        <i class="fa fa-exclamation-circle modal-icon-circle"></i>
        <button
          class="modal-icon-circle-btn"
          type="button"
          @click="closeAlertModal"
        >
          <img
            alt="btn-close"
            class="modal-btn-close"
            src="../assets/img/wysiwyg/button-close-x.png"
          />
        </button>
      </div>
      <p class="modal-header-alert">File size to large</p>
      <span class="modal-text-alert"
        >The attachment exceeds 10 MB and cannot be uploaded.</span
      >
      <button class="btn btn-primary btn-sm mt-3" @click="closeAlertModal">
        OK
      </button>
    </file-max-alert>
    <p class="text-muted send-as smaller mt-3">
      This message will be send as:
      <span title="Change sender" class="send-as-default"> {{ user }}</span>
    </p>
    <p class="text-muted">
      If you aren't a current list member, sending this message will subscribe
      you.
    </p>
    <div class="col-3">
      <button @click.prevent="onSend" class="btn btn-primary btn-block">
        Send
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import Editor from "@tinymce/tinymce-vue";
import IFileUploadService from "@/application/IFileUploadService";
import { ISelectedFile } from "@/domain/ISelectedFile";
import FileMaxSizeAlert from "@/components/FileMaxSizeAlert.vue";
import ISecurityService from "@/application/ISecurityService";
import IUploadedFile from "@/domain/IUploadedFile";
import IConfigurationService from "@/application/IConfigurationService";
import IConfiguration from "@/domain/IConfiguration";

type FileItem = ISelectedFile & {
  clazz: Array<string>;
  iconClazz: Array<string>;
};

@Component({
  components: {
    editor: Editor,
    fileMaxAlert: FileMaxSizeAlert,
  },
})
export default class WysiwygEditor extends Vue {
  @Prop({ required: true }) user!: string;
  @Inject("IFileUploadService") fileUploadedService!: IFileUploadService;
  @Inject("ISecurityService") securityService!: ISecurityService;
  @Inject("IConfigurationService") configService!: IConfigurationService;
  private content = "";
  private imageSelected!: File;
  private uploadedFile: IUploadedFile[] = [];
  selectedFiles: ISelectedFile[] = [];
  private fileBase64!: string;
  showModal = false;
  private wysiwygUuid!: string;
  private config: IConfiguration | null = null;
  created() {
    this.wysiwygUuid = this.generateUUIDString();
  }
  mounted() {
    this.editorSettings;
    this.configService.getConfig().then((config) => {
      this.config = config;
    });
  }
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  }

  get editorSettings(): object {
    return {
      height: "254px",
      menubar: false,
      contextmenu: false,
      resize: true,
      content_security_policy: "img-src https: data: blob: 'self'",
      plugins: ["image", "table", "lists", "code", "link", "autolink"],
      toolbar:
        "bold italic underline strikethrough subscript superscript bullist numlist table code blockquote link image attachFileButton",
      table_toolbar:
        "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
      table_advtab: false,
      link_title: false,
      content_style: "body { font-family: Whitney Ssm, sans-serif; }",
      file_picker_callback: this.openImagePicker,
      file_picker_types: "media image",
      link_default_protocol: "https",
      link_assume_external_targets: "https",
      target_list: false,
      browser_spellcheck: true,
      setup: (editor: any, callback: any) => {
        this.registerAttachFileButton(editor, callback);
      },
    };
  }
  private get internalFiles(): Array<FileItem> {
    const newSelectedFile: Array<FileItem> = [];
    for (let i = 0; i < this.selectedFiles.length; i++) {
      newSelectedFile.push({
        ...this.selectedFiles[i],
        clazz: this.getProgressBarClass(
          this.selectedFiles[i].uploadProgress,
          this.selectedFiles[i].uploadError
        ),
        iconClazz: this.getShowProgressIcon(
          this.selectedFiles[i].uploadProgress,
          this.selectedFiles[i].uploadError
        ),
      });
    }
    return newSelectedFile;
  }
  generateUUIDString() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  getProgressBarClass(
    uploadPercentage: number,
    uploadError: boolean
  ): Array<string> {
    if (uploadPercentage && !uploadError) {
      return ["progress-bar", "complete"];
    } else if (uploadError && uploadPercentage) {
      return ["progress-bar", "error"];
    } else {
      return ["progress-bar"];
    }
  }

  getShowProgressIcon(
    uploadPercentage: number,
    uploadError: boolean
  ): Array<string> {
    return uploadPercentage && !uploadError
      ? ["progress-icon", "show"]
      : ["progress-icon"];
  }

  private openImagePicker(callback: any): void {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = (e: any) => {
      this.imageSelected = e.target.files[0];
      this.imageToBase64(callback, this.imageSelected);
    };
    input.click();
  }
  private registerAttachFileButton(editor: any, callback: any): void {
    editor.ui.registry.addIcon(
      "paperClip",
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">\n' +
        '  <path d="M3.29221 13.4726C1.77059 11.9026 1.79895 9.37076 3.3283 7.80644L8.79079 2.21892C9.94485 1.03841 11.8208 1.0383 12.9749 2.21892C14.1179 3.38804 14.1193 5.27436 12.9749 6.44486L8.21324 11.3108C7.43577 12.106 6.16551 12.0949 5.40121 11.2848C4.66489 10.5043 4.68851 9.26727 5.43903 8.49957L9.18233 4.67574C9.34332 4.51132 9.60712 4.5085 9.77155 4.66947L10.3669 5.25225C10.5313 5.41324 10.5341 5.67704 10.3732 5.84147L6.63025 9.66493C6.50181 9.79631 6.49389 10.0146 6.61337 10.1413C6.72723 10.262 6.90621 10.264 7.02191 10.1456L11.7836 5.27968C12.2944 4.75723 12.2944 3.90663 11.7833 3.3839C11.2836 2.87278 10.4824 2.87252 9.98243 3.3839L4.51991 8.97139C3.61463 9.89743 3.60067 11.3964 4.4889 12.3129C5.37457 13.2267 6.80621 13.2279 7.69353 12.3203L12.1743 7.73702C12.3351 7.57246 12.5989 7.56949 12.7635 7.73035L13.3592 8.31274C13.5238 8.4736 13.5268 8.7374 13.3659 8.90196L8.88517 13.4852C7.33371 15.0722 4.82416 15.0534 3.29221 13.4726Z" fill="#006AA4"/>\n' +
        "</svg>"
    );
    editor.ui?.registry.addButton("attachFileButton", {
      text: "Add attachment",
      icon: "paperClip",
      onAction: () => {
        this.attachmentFileButton(callback);
      },
    });
  }

  private attachmentFileButton(callback: any) {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.click();
    input.addEventListener("change", async (event) => {
      const filesInput = event.target as HTMLInputElement;
      const files = filesInput.files;
      if (files) {
        const allowedTypes = [
          "image/jpeg",
          "image/png",
          "application/vnd.ms-excel",
          "application/msword",
          "application/vnd.ms-powerpoint",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ];
        const maxSize = 10 * 1024 * 1024; // 10MB

        const filterFiles = Array.from(files).filter((file) => {
          return (
            allowedTypes.includes(file.type) &&
            !this.selectedFiles.some(
              (existingFile) => existingFile.file.name === file.name
            ) &&
            file.size <= maxSize
          );
        });
        const totalSelectedSize = filterFiles.reduce(
          (total, file) => total + file.size,
          0
        );
        const allTotal =
          totalSelectedSize +
          this.selectedFiles.reduce((total, file) => total + file.file.size, 0);
        if (totalSelectedSize > maxSize || allTotal > maxSize) {
          this.showModal = true;
          return;
        } else {
          const selectedFiles: ISelectedFile[] = filterFiles.map((file) => ({
            file,
            uploadProgress: 0,
            uploadError: false,
          }));
          this.selectedFiles.push(...selectedFiles);
          this.fileToBase64(selectedFiles, callback);
        }
      }
    });
  }
  private onRemoveFile(fileToRemove: FileItem) {
    const index = this.selectedFiles.findIndex(
      (file) => file.file.name === fileToRemove.file.name
    );
    if (index !== -1) {
      this.fileUploadedService.deleteImage(
        this.wysiwygUuid,
        this.uploadedFile[index].fileId
      );
    }
    this.selectedFiles.splice(index, 1);
    this.uploadedFile.splice(index, 1);
  }
  private closeAlertModal() {
    this.showModal = false;
  }

  private attachmentFileSize(value: number): string {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (value === 0) return "0 Byte";
    const i = Math.floor(Math.log(value) / Math.log(1024));
    return (value / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
  }
  private imageToBase64(callback: any, file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const url = e.target.result;
      callback(url, { alt: file.name });
      this.fileBase64 = url;
      this.fileUploadedService.uploadFile(
        this.imageSelected.name,
        this.wysiwygUuid,
        this.fileBase64,
        this.imageSelected.type
      );
    };
    reader.readAsDataURL(file);
  }
  private fileToBase64(files: ISelectedFile[], callback?: any) {
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const url = e.target.result;
        if (callback) callback(url, { alt: file.file.name });
        this.fileBase64 = url;
        this.onUploadFile(this.fileBase64, file.file.name, file.file.type);
      };
      reader.readAsDataURL(file.file);
    }
  }
  private onUploadFile(base64: string, name: string, type: string): void {
    this.fileUploadedService
      .uploadFile(name, this.wysiwygUuid, base64, type)
      .then((file) => {
        const fileIndex = this.selectedFiles.findIndex(
          (file) => file.file.name === name
        );
        if (fileIndex !== -1) {
          this.selectedFiles[fileIndex].uploadProgress = 100;
        }
        this.uploadedFile.push(file);
      })
      .catch(() => {
        this.onErrorUpload(name);
      });
  }

  private onErrorUpload(fileName: string) {
    const errorFile = this.selectedFiles.find(
      (selectedFile) => selectedFile.file.name === fileName
    );
    errorFile!.uploadError = true;
    errorFile!.uploadProgress = 100;
  }
  private onSend() {
    this.$emit("onSend", {
      content: this.content,
      uuid: this.wysiwygUuid,
    });
  }
}
</script>

<style scoped lang="scss">
.editor {
  display: block;
  ::v-deep .tox-button .tox-button--icon .tox-button--naked .tox-browse-url {
    display: none !important;
  }
  ::v-deep .tox-statusbar__branding {
    display: none;
  }

  ::v-deep .tox-toolbar__primary {
    background: none;
  }

  ::v-deep .tox-tbtn svg {
    fill: #535454;
  }

  ::v-deep .tox-tbtn__select-label {
    font-size: 1rem;
    font-family: "Whitney SSm A", "Whitney SSm B", "Arial", sans-serif;
    color: #007bbd;
  }

  ::v-deep .attachment-list-container {
    margin-top: 1%;
    width: 100%;
    height: 38px;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
  }

  ::v-deep .progress {
    width: 0;
    height: 100%;
    background-color: #cccccc;
    transition: width 0.3s;
    border-radius: 2px;
  }

  ::v-deep .progress-success {
    width: 0;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.3s;
    border-radius: 2px;
    border: 1px solid #006aa4;
  }

  ::v-deep .progress-label {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0;
    line-height: 38px;
    color: #666;
    font-weight: bold;
  }

  ::v-deep .progress-label {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0;
    line-height: 38px;
    color: #666;
    font-weight: bold;
  }

  ::v-deep .progress-bar.complete {
    background-color: #ffffff;
    border: 1px solid #006aa4;
    width: 100%;
  }

  ::v-deep .progress-bar.error {
    background-color: #ffffff;
    border: 1px solid #fda29b;
    width: 100%;
  }
  ::v-deep .total-file-size {
    font-weight: 400;
  }
  ::v-deep .total-file-size-success {
    font-weight: 400;
    color: #007bbd;
  }
  ::v-deep .total-file-size-error {
    font-weight: 400;
    color: #b00020;
  }
  ::v-deep .progress-bar-upload-progress {
    font-weight: 400;
  }
  ::v-deep .progress-bar-upload-progress-error {
    font-weight: 325;
    font-size: 14px;
    color: #b00020;
  }
  ::v-deep .progress-bar.hide {
    display: none;
  }

  ::v-deep .progress-icon {
    display: none;
  }
  ::v-deep .progress-close {
    margin-right: 10px;
  }
  ::v-deep .progress-icon.show {
    color: #007bbd;
    display: inline;
    font-weight: 325;
    font-size: 14px;
  }
  ::v-deep .file-name {
    color: #4d4d4d;
    font-weight: 350;
    font-size: 14px;
  }

  ::v-deep .file-name-success {
    color: #007bbd;
    font-weight: 350;
    font-size: 14px;
  }

  ::v-deep .file-name-error {
    color: #8d001a;
    font-weight: 350;
    font-size: 14px;
  }
  ::v-deep .file-btn-close {
    padding: 0;
    border: none;
    background: none;
    margin-right: -8px;
  }
  ::v-deep .file-icon-close {
    padding: 8px;
  }

  ::v-deep .icon-modal {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .modal-header-alert {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    margin-top: 2%;
  }
  ::v-deep .modal-text-alert {
    color: #808080;
    font-size: 14px;
    line-height: 20px;
  }
  ::v-deep .modal-icon-circle {
    color: #ffc107;
    font-size: 20px;
    margin-top: 10px;
  }
  ::v-deep .modal-icon-circle-btn {
    padding: 0;
    border: none;
    background: none;
  }
  ::v-deep .modal-btn-close {
    cursor: pointer;
  }
}
</style>
