<template>
  <transition name="modal-slide">
    <div class="modal" v-if="show">
      <div class="modal-content">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class FileMaxSizeAlert extends Vue {
  @Prop({ default: false }) private show!: boolean;
}
</script>
<style scoped>
.modal-slide-enter-active,
.modal-slide-leave-active {
  transition: all 0.3s;
}
.modal-slide-enter,
.modal-slide-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-content {
  position: relative;
  margin: 15% auto;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  height: 224px;
  background-color: #fff;
  border-radius: 5px;
}
.close {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 28px;
  cursor: pointer;
}
</style>
