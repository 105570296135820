import ITimeZoneService from "@/application/ITimeZoneService";
import FetchUtils from "@/infrastructure/FetchUtils";

const DEFAULT_HTTP_HEADERS = {
  "Content-Type": "application/json",
};

export default class TimeZoneService implements ITimeZoneService {
  async getTimeZones(): Promise<Array<string>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch("/api/v1/time-zones", { headers });
    const data = await response.json();

    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return data as Array<string>;
  }
}
