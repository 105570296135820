<template>
  <div class="contextual__section-title" ref="sectionTitle">
    <a v-if="threadViewElementUrl" :href="threadViewElementUrl">
      <h1>{{ sectionTitle }}</h1>
    </a>
    <h1 v-else>{{ sectionTitle }}</h1>
    <slot> </slot>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

const MAILING_LIST_LANDING_PAGE_REGEX = /\/mailing-list\/[^/]+\/$/;
const THREAD_PAGE_REGEX = /^\/hyperkitty\/list\/[^/]+\/thread\/[^/]+\/$/;
const NEWS_FEED_PAGE_REGEX = /^\/news-feed\//;
const SEARCH_PAGE_REGEX = /^\/hyperkitty\/search/;
const REFERRER_PAGE_STORAGE_KEY = "co_thread_view_referrer_page";

@Component
export default class SectionTitleView extends Vue {
  private threadViewElementUrl: string | null = "";
  @Prop({ required: true }) private sectionTitle!: string;

  mounted() {
    this.scheduleDisplayThreadNavigation();
    this.scheduleDisplayContextualNavigation();
    this.scheduleSaveThreadViewReferrerPage();
    this.doIfInMailiListDashboard();
  }

  private scheduleDisplayThreadNavigation(): void {
    this.performBasedOnContext(
      () => {
        document
          .querySelectorAll('[data-ref="back-to-news-feed-link"]')
          .forEach((element: Element) => {
            (element as HTMLElement).style.display = "block";
          });
      },
      () => {
        const allThreadsButton = document.querySelector(
          '[data-ref="all-threads-button"]'
        );
        if (allThreadsButton) {
          (allThreadsButton as HTMLElement).classList.add("d-flex");
        }
        const backToMList = document.querySelector(
          '[data-ref="back-to-mlist"]'
        );
        if (backToMList) {
          (backToMList as HTMLElement).style.display = "block";
        }
      }
    );
  }

  private doIfInMailiListDashboard(): void {
    const mailingListTitleDiv = document.querySelector(
      ".mailing-list-title"
    ) as HTMLElement;
    if (mailingListTitleDiv) {
      const mailingListTitle = mailingListTitleDiv.textContent?.replaceAll(
        "\n ",
        ""
      ) as string;
      const sectionTitleElement = this.$refs.sectionTitle as HTMLElement;
      if (sectionTitleElement) {
        this.sectionTitle = mailingListTitle;
      }
    }
  }
  private scheduleDisplayContextualNavigation(): void {
    const sectionTitleElement = this.$refs.sectionTitle as HTMLElement;
    this.performBasedOnContext(
      () => {
        if (sectionTitleElement) {
          this.sectionTitle = "News Feed";
        }
      },
      () => {
        const threadViewElement = document.querySelector(
          '[data-ref="thread-view-container"]'
        ) as HTMLElement;
        if (threadViewElement) {
          const url = threadViewElement.dataset.mailingListUrl as string;
          this.sectionTitle = threadViewElement.dataset
            .mailingListName as string;
          this.threadViewElementUrl = url;
        }
      }
    );
  }
  private performBasedOnContext(
    doIfReferrerIsNewsFeed: () => void,
    doIfReferrerIsMailingListLandingPage: () => void
  ): void {
    if (THREAD_PAGE_REGEX.test(window.location.pathname)) {
      const referrerPage = sessionStorage.getItem(REFERRER_PAGE_STORAGE_KEY);
      if (referrerPage && NEWS_FEED_PAGE_REGEX.test(referrerPage)) {
        doIfReferrerIsNewsFeed();
      } else {
        doIfReferrerIsMailingListLandingPage();
      }
    }
  }
  private scheduleSaveThreadViewReferrerPage(): void {
    const currentPath = window.location.pathname;
    if (
      MAILING_LIST_LANDING_PAGE_REGEX.test(currentPath) ||
      NEWS_FEED_PAGE_REGEX.test(currentPath) ||
      SEARCH_PAGE_REGEX.test(currentPath)
    ) {
      sessionStorage.setItem(REFERRER_PAGE_STORAGE_KEY, currentPath);
    }
  }
}
</script>
