<template>
  <div class="email-body">
    <div v-if="loading" style="display: flex; justify-content: center">
      <LoadingSkeleton width="100%" height="375px" radius="0" />
    </div>
    <div v-else>
      <MessageContent
        :html-content="this.htmlContent.htmlContent"
        :img-blur-state="sessionShowImgValue"
      ></MessageContent>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Inject } from "vue-property-decorator";
import MessageContent from "@/components/MessageContent.vue";
import IBlurImageToggleService from "@/application/IBlurImageToggleService";
import IThreadService from "@/application/IThreadService";
import IThread from "@/domain/IThread";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
@Component({ components: { MessageContent, LoadingSkeleton } })
export default class ThreadMessageView extends Vue {
  @Inject("IBlurImageToggleService")
  blurImageToggleService!: IBlurImageToggleService;
  @Inject("IThreadService") messageContentHtml!: IThreadService;
  @Prop({ required: true })
  mlistName!: string;
  @Prop({ required: true })
  emailMessageIdHash!: string;
  private sessionShowImgValue: boolean | null = null;
  private htmlContent: IThread | null = null;
  private loading = true;
  private async mounted() {
    this.blurImageToggleService.getShowImg().subscribe((showImg: boolean) => {
      this.sessionShowImgValue = showImg;
    });
    try {
      this.htmlContent = await this.messageContentHtml.getThread(
        this.mlistName,
        this.emailMessageIdHash
      );
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss"></style>
