<template>
  <div>
    <span>{{ totalCount }}</span>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Inject } from "vue-property-decorator";

import IMessageReport from "@/domain/IMessageReport";
import IMessageReportService from "@/application/IMessageReportService";

@Component
export default class ReportMessageCounter extends Vue {
  @Inject("IMessageReportService") reportMessageService!: IMessageReportService;
  @Prop({ required: true }) mList!: string;
  private limit = 10;
  private totalCount = 0;
  private page = 1;
  private status = "reported";
  async mounted() {
    this.reportMessageService
      .getMessageReports()
      .subscribe((messageReportsPage) => {
        this.totalCount = messageReportsPage.count;
      });
    await this.reportMessageService.fetchMessageReports(
      this.mList,
      this.limit,
      this.page,
      this.status
    );
  }
}
</script>

<style scoped lang="scss"></style>
