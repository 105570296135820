<template>
  <div ref="sidebar-wrapper" id="sidebar-wrapper" :class="clazz">
    <button v-if="showMobileMenu" class="close-button" @click="closeMenu">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Vue } from "vue-property-decorator";
import { IMobileMenuService } from "@/application/IMobileMenuService";
import { fromEvent, throttleTime } from "rxjs";

const LAYOUT_BREAKPOINT_MD = 768;

@Component
export default class SidebarView extends Vue {
  @Inject("IMobileMenuService") mobileMenuService!: IMobileMenuService;

  private clazz = ["d-md-flex", "flex-column", "d-flex"];
  private showMobileMenu = false;

  private mounted(): void {
    const openRequests$ = this.mobileMenuService.getOpenRequest();
    openRequests$.subscribe(() => this.openMenu());
    openRequests$.subscribe(() => this.addToggledClassToWrapper());

    const windowResizes$ = fromEvent(window, "resize").pipe(throttleTime(500));
    windowResizes$.subscribe(() => {
      if (window.outerWidth > LAYOUT_BREAKPOINT_MD) {
        this.closeMenu();
      }
    });
  }

  private openMenu(): void {
    this.showMobileMenu = true;
    const sidebarWrapper = this.getSidebarWrapper();
    sidebarWrapper.setAttribute(
      "style",
      "display: flex !important; z-index: 2000; width: 100% !important; margin-left: 0 !important; top:0;"
    );
    sidebarWrapper
      .querySelector(".sidebar-brand div:first-child")
      ?.setAttribute("style", "display: block !important;");
  }

  private closeMenu(): void {
    this.showMobileMenu = false;
    const sidebarWrapper = this.getSidebarWrapper();
    sidebarWrapper.setAttribute("style", "");
    sidebarWrapper
      .querySelector(".sidebar-brand div:first-child")
      ?.setAttribute("style", "");
  }

  private getSidebarWrapper(): Element {
    return this.$refs["sidebar-wrapper"] as Element;
  }

  /**
   * Some elements in sidebar rely on #wrapper element having the toggled class when expanded.
   * @private
   */
  private addToggledClassToWrapper(): void {
    const wrapperElement = document.getElementById("wrapper") as HTMLElement;
    wrapperElement?.classList?.add("toggled");
  }
}
</script>

<style lang="scss" scoped>
#sidebar-wrapper {
  position: fixed;
  width: 100vw;
  z-index: 2001 !important;
  height: 100%;

  .close-button {
    position: absolute;
    right: 1rem;
    top: 0.7rem;
    border: none;
    background: none;
    font-size: 1.5rem;
  }

  .brand {
    position: absolute;
    left: 1rem;
    top: 0.9rem;
    color: #000000;
  }
}

#wrapper.toggled #sidebar-wrapper {
  overflow: hidden;
  & ::v-deep .nav-item {
    width: inherit;
  }
  & ::v-deep .nav-tooltip:before {
    display: none;
  }
  & ::v-deep .nav-tooltip:after {
    display: none;
  }
  & ::v-deep .nav-item-text {
    display: inline-block;
  }
}

#wrapper #sidebar-wrapper {
  overflow: visible;
  & ::v-deep .nav-tooltip {
    position: relative;
  }
  & ::v-deep .nav-tooltip:before {
    position: absolute;
    z-index: 2;
    display: none;
    width: max-content;
    max-width: 200px;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 18px; /* 150% */
    background: var(--Gray-900, #1a1a1a);
    color: white;
    content: attr(data-title);
  }
  & ::v-deep .nav-tooltip:after {
    position: absolute;
    z-index: 1;
    display: none;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    content: "";
  }
  & ::v-deep .nav-tooltip:hover:before,
  & ::v-deep .nav-tooltip:hover:after {
    display: inline-block;
  }
  & ::v-deep .nav-tooltip.right:before {
    top: 50%;
    left: calc(100% + 5px);
    transform: translateY(-50%);
  }
  & ::v-deep .nav-tooltip.right:after {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    border-left-width: 0;
    border-right-color: #1a1a1a;
  }
  & ::v-deep .nav-item {
    width: 60px;
  }
  & ::v-deep .nav-item-text {
    display: none;
  }
}
</style>
