<template>
  <div class="toggle-show-blur-img">
    <label class="toggle-img-blur">
      <input
        class="toggle_show_blur_img"
        v-model="inputToggleState"
        type="checkbox"
      />
      <span class="slider-img-blur round"></span>
    </label>
    <span class="show-img-text"> Show images </span>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Inject } from "vue-property-decorator";
import IBlurImageToggleService from "@/application/IBlurImageToggleService";

@Component
export default class ToggleShowBlurImg extends Vue {
  @Inject("IBlurImageToggleService")
  blurImageToggleService!: IBlurImageToggleService;
  private toggleState: boolean | null = null;

  mounted() {
    this.blurImageToggleService.getShowImg().subscribe((showImg: boolean) => {
      this.toggleState = showImg;
    });
  }

  get inputToggleState(): boolean {
    return this.toggleState ?? false;
  }

  set inputToggleState(toggleStateNewValue: boolean) {
    this.blurImageToggleService.setShowImg(toggleStateNewValue);
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";
.toggle-show-blur-img {
  display: flex;
  gap: 8px;
  position: fixed;
  right: 1.9em;
  @media (max-width: $breakpoint-lg) {
    display: flex;
    position: absolute;
    left: 2%;
  }
}
.toggle-img-blur {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}
.toggle-img-blur:focus-within {
  border-color: rgba(0, 123, 255, 1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 123, 255, 1);
  outline: 0 none;

  padding: 10px;
  border-radius: 25px;
}

.toggle-img-blur input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-img-blur {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-img-blur:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider-img-blur {
  background-color: #2196f3;
}

input:focus + .slider-img-blur {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider-img-blur:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

.slider-img-blur.round {
  border-radius: 22px;
}

.slider-img-blur.round:before {
  border-radius: 50%;
}

.show-img-text {
  @media (max-width: $breakpoint-lg) {
    color: white;
  }
}
</style>
