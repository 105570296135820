import IProfileSummary from "@/domain/IProfileSummary";
import IProfile from "@/domain/IProfile";
import { inject, singleton } from "tsyringe";
import ISecurityService from "@/application/ISecurityService";
import ISubscription from "@/domain/ISubscription";
import IMessage from "@/domain/IMessage";
import FetchUtils from "@/infrastructure/FetchUtils";
import IUserService from "@/application/IUserService";

type AdditionalInformationItemDTO = { field: string; value: string };

type ProfileDTO = {
  display_name?: string;
  full_name?: string;
  about_me?: string;
  additional_information?: Array<AdditionalInformationItemDTO>;
};

type ProfileSummaryDTO = {
  post_count: number;
  thread_count: number;
  vote_count: number;
  date_joined: string;
  display_name: string;
  image_url: string;
};

type SubscriptionDTO = {
  list_id: string;
  list_name: string;
  list_url: string;
  archived: boolean;
  mailing_list?: {
    name: string;
    display_name: string;
    description: string;
    cover_image_url: string;
    banner_image_url: string;
  };
};

type PostDTO = {
  mailinglist: {
    name: string;
    display_name: string;
    description: string;
  };
  message_id_hash: string;
  subject: string;
  content: string;
  html_content: string;
  sender: {
    mailman_id: string;
    name: string;
    image_url: string;
  };
  sender_name: string;
  date: string;
  get_votes: {
    likes: number;
    dislikes: number;
    status: string;
  };
  parent: object;
  thread_id: string;
  flagged_by_moderator: boolean;
};

const DEFAULT_HTTP_HEADERS = {
  "Content-Type": "application/json",
};

@singleton()
export default class UserService implements IUserService {
  constructor(
    @inject("ISecurityService") private securityService?: ISecurityService
  ) {}

  async gerProfileSummary(userId: string): Promise<IProfileSummary> {
    const response = await fetch(`/api/v1/users/${userId}/summary`);
    const data = (await response.json()) as ProfileSummaryDTO;
    return {
      postCount: data.post_count,
      threadCount: data.thread_count,
      voteCount: data.vote_count,
      dateJoined: data.date_joined,
      displayName: data.display_name,
      imageUrl: data.image_url,
    };
  }

  async getProfile(userId: string): Promise<IProfile> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch(`/api/v1/users/${userId}/profile`, {
      headers,
    });
    const data = await response.json();

    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return UserService.toIProfile(data as ProfileDTO);
  }

  private static toIProfile(profileDTO: ProfileDTO): IProfile {
    return {
      displayName: profileDTO.display_name ?? "",
      fullName: profileDTO.full_name ?? "",
      aboutMe: profileDTO.about_me ?? undefined,
      additionalInformation: profileDTO.additional_information ?? [],
    };
  }

  public async getSubscriptions(userId: string): Promise<Array<ISubscription>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch(`/api/v1/users/${userId}/subscriptions`, {
      headers,
    });
    const data = await response.json();

    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return (data as Array<SubscriptionDTO>).map(UserService.toISubscription);
  }

  private static toISubscription(
    subscriptionDTO: SubscriptionDTO
  ): ISubscription {
    return {
      listId: subscriptionDTO.list_id,
      listName: subscriptionDTO.list_name,
      listUrl: subscriptionDTO.list_url,
      archived: subscriptionDTO.archived,
      displayName: subscriptionDTO.mailing_list?.display_name,
      description: subscriptionDTO.mailing_list?.description,
      coverImageUrl: subscriptionDTO.mailing_list?.cover_image_url,
      bannerImageUrl: subscriptionDTO.mailing_list?.banner_image_url,
    };
  }

  public async getLastEmails(userId: string): Promise<Array<IMessage>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch(`/api/v1/users/${userId}/last-emails`, {
      headers,
    });
    const data = await response.json();

    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return (data as Array<PostDTO>).map(UserService.toIMessage);
  }

  private static toIMessage(post: PostDTO): IMessage {
    return {
      idHash: post.message_id_hash,
      subject: post.subject,
      content: post.content,
      htmlContent: post.html_content,
      sender: {
        mailmanId: post.sender.mailman_id,
        name: post.sender.name,
        imageUrl: post.sender.image_url,
      },
      date: post.date,
      likes: post.get_votes.likes,
      dislikes: post.get_votes.dislikes,
      mailingList: {
        name: post.mailinglist.name,
        displayName: post.mailinglist.display_name,
      },
      threadId: post.thread_id,
      flaggedByModerator: post.flagged_by_moderator,
    };
  }
}
