<template>
  <div>
    <div @click="toggleReply">
      <a class="reply-form__header reply" href="#">
        <i class="fa fa-reply mr-3 mb-3"></i><span>Reply</span>
      </a>
    </div>
    <ReplyMessageSuccess v-if="success"></ReplyMessageSuccess>
    <NewMessageFormView
      :enabled="this.reply"
      :mlist-name="mlistName"
      :user="user"
      :is-reply="true"
      :id-hash="messageIdHash"
      @onSend="sendReply"
    />
    <div v-if="reply">
      <div class="col-3 btn-cancel">
        <a class="btn" @click="toggleReply">Cancel</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Inject } from "vue-property-decorator";
import WysiwygEditor from "@/components/WysiwigEditor.vue";
import IMessageService from "@/application/IMessageService";
import SanitizeHtmlContentService from "@/infrastructure/SanitizeHtmlContentService";
import ReplyMessageSuccess from "@/components/ReplyMessageSuccess.vue";
import IProfileService from "@/application/IProfileService";
import IProfileSummary from "@/domain/IProfileSummary";
import NewMessageFormView from "@/views/NewMessageFormView.vue";

@Component({
  components: { ReplyMessageSuccess, WysiwygEditor, NewMessageFormView },
})
export default class ReplyMessageView extends Vue {
  @Inject("IMessageService") messageService!: IMessageService;
  @Inject("IProfileService") profileService!: IProfileService;
  @Prop({ required: true }) user!: string;
  @Prop({ required: true }) mlistFqdn!: string;
  @Prop({ required: true }) mlistName!: string;
  @Prop({ required: true }) messageIdHash!: string;
  @Prop({ required: true }) mlistId!: string;

  private reply = false;
  private success = false;
  private profileSummary: IProfileSummary | null = null;
  private subjectEmpty = true;

  mounted() {
    this.profileService.getProfileSummary().then((profile) => {
      this.profileSummary = profile;
    });
  }

  private toggleReply() {
    this.reply = !this.reply;
  }

  private sendReply(data: {
    subject: string | null;
    content: string;
    uuid: string;
    newThread: boolean;
  }) {
    if (data.newThread && !data.subject) {
      return;
    }
    this.messageService
      .replyMessage(
        data.newThread,
        data.subject ?? "",
        SanitizeHtmlContentService.sanitizeHtmlContent(data.content),

        this.messageIdHash,
        this.mlistFqdn,
        this.mlistName,
        this.mlistId,
        data.uuid
      )
      .then(() => {
        this.success = true;
        this.messageService.pushReply(
          0,
          {
            mailingList: {
              name: this.mlistName,
              display_name: this.mlistName,
              description: "",
            },
            idHash: this.messageIdHash,

            subject: data.subject ?? "",
            content: data.content,
            html_content: data.content,

            date: "",
            threadDepth: 0,
            threadOrder: 0,
            threadId: 1,
            sender: {
              mailmanId: "",

              name: this.user,

              imageUrl: this.profileSummary!.imageUrl,
            },
            likes: "",
            dislikes: "",
            attachments: [],

            wysiwygUuid: data.uuid,
            flaggedByModerator: false,
          },
          true
        );
        this.reply = false;
      });
  }
}
</script>

<style scoped lang="scss">
.new-thread {
  display: none;
}
.btn-cancel {
  display: flex;
  left: 25%;
  bottom: 2.4em;
}
</style>
