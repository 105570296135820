import { render, staticRenderFns } from "./PublicProfileView.vue?vue&type=template&id=43fe978f&scoped=true"
import script from "./PublicProfileView.vue?vue&type=script&lang=ts"
export * from "./PublicProfileView.vue?vue&type=script&lang=ts"
import style0 from "./PublicProfileView.vue?vue&type=style&index=0&id=43fe978f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_prettier@2.6.2_vue-template-compiler@2.6.14_webpack@5.91.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43fe978f",
  null
  
)

export default component.exports