<template>
  <section class="mb-2">
    <ul class="nav nav-tabs" id="profile-posting-activity" role="tablist">
      <li class="nav-item flex-fill text-center" role="presentation">
        <a
          class="nav-link active"
          id="my-subscriptions-tab"
          data-toggle="tab"
          href="#my-subscriptions"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          >{{ userName }} Subscriptions</a
        >
      </li>
      <li class="nav-item flex-fill text-center" role="presentation">
        <a
          class="nav-link"
          id="my-posts-tab"
          data-toggle="tab"
          href="#my-posts"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
          >{{ userName }} Posts</a
        >
      </li>
    </ul>

    <div class="tab-content p-0">
      <div
        class="tab-pane active"
        id="my-subscriptions"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <PublicProfileSubscriptionsView :userId="userId" />
      </div>
      <div
        class="tab-pane"
        id="my-posts"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <PublicProfilePostsView :userId="userId" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import PublicProfilePostsView from "@/views/PublicProfilePostsView.vue";
import PublicProfileSubscriptionsView from "@/views/PublicProfileSubscriptionsView.vue";
@Component({
  components: {
    PublicProfileSubscriptionsView,
    PublicProfilePostsView,
  },
})
export default class PublicProfilePostingActivity extends Vue {
  @Prop({ required: true }) private userId!: string;
  @Prop({ required: true }) private userName!: string;
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_base.scss";

section {
  width: 100%;
  max-width: $section-max-width;
  display: none;
  @media (min-width: $breakpoint-md) {
    display: block;
  }
}

.nav-tabs {
  border-bottom-style: none;
}

.nav-item {
  a {
    border-style: none;
    color: #1a1a1a;
    font-weight: lighter;
    border-bottom-style: solid;
    border-bottom-width: 0.2rem;
    &:hover:not(.active) {
      background-color: #b3b3b3;
      border-bottom-color: #b3b3b3;
    }
    &.active {
      font-weight: 500;
      border-bottom-color: var(--blue);
      cursor: default;
      box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2),
        0px 1px 3px rgba(50, 50, 71, 0.1);
    }
  }
}

.tab-content {
  box-sizing: border-box;
  background-color: #ffffff;
  border: 0;
  padding: 1rem 1rem 1rem 1rem;
  font-size: 1rem;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2),
    0px 1px 3px rgba(50, 50, 71, 0.1);

  iframe {
    width: 100%;
    min-height: 30vh;
    border-style: none;
  }
}
</style>
