import { inject, singleton } from "tsyringe";
import ISecurityService from "@/application/ISecurityService";
import IMessageReportService from "@/application/IMessageReportService";
import FetchUtils from "@/infrastructure/FetchUtils";
import IMessageReportsPage from "@/domain/IMessageReportsPage";
import IMessageReport from "@/domain/IMessageReport";

import { BehaviorSubject, Observable } from "rxjs";

const DEFAULT_HTTP_HEADERS = {
  "Content-Type": "application/json",
};

const CSRF_TOKEN_HEADER_NAME = "X-CSRFToken";

type ReportMessageDTO = {
  id: number;
  message_id_hash: string;
  message_subject: string;
  reason: string;
  reporter_email: string;
  created_at: string;
  status: string;
  thread_id: string;
};

type MessageReportResponseDTO = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<ReportMessageDTO>;
};

@singleton()
export default class MessageReportService implements IMessageReportService {
  private messageReportsPage$ = new BehaviorSubject<IMessageReportsPage>({
    items: [],
    count: 0,
  });
  constructor(
    @inject("ISecurityService") private securityService?: ISecurityService
  ) {}

  async getUserMessageReport(
    mList: string,
    messageIdHash: string,
    reportedByUser: boolean
  ): Promise<Array<IMessageReport>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const csrfToken = this.securityService?.getToken();
    if (csrfToken) {
      headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;
    }
    const response = await fetch(
      `/api/v1/${mList}/message-reports?message_id_hash=${messageIdHash}&reported_by_user=${reportedByUser}`,
      {
        headers,
      }
    );
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return (data as MessageReportResponseDTO).results.map(
      MessageReportService.toIReportMessage
    );
  }

  /**
   * Create a message report
   * @param mList for Mailing list id
   * @param messageIdHash for email message_id_hash
   * @param reason for being reported
   * @return data payload.
   */
  async reportMessage(
    mList: string,
    messageIdHash: string,
    reason: string
  ): Promise<void> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const csrfToken = this.securityService?.getToken();
    if (csrfToken) {
      headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;
    }
    const response = await fetch(`/api/v1/${mList}/message-reports`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        message_id_hash: messageIdHash,
        reason: reason,
      }),
    });
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return data;
  }

  async fetchMessageReports(
    mList: string,
    limit: number,
    page: number,
    status: string
  ): Promise<void> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const csrfToken = this.securityService?.getToken();
    if (csrfToken) {
      headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;
    }
    const response = await fetch(
      `/api/v1/${mList}/message-reports?limit=${limit}&page=${page}&status=${status}`,
      {
        headers,
      }
    );
    const data = (await response.json()) as MessageReportResponseDTO;

    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    this.messageReportsPage$.next({
      items: data.results.map((item) =>
        MessageReportService.toIReportMessage(item)
      ),
      count: data.count,
    });
  }
  getMessageReports(): Observable<IMessageReportsPage> {
    return this.messageReportsPage$.asObservable();
  }

  public static toIReportMessage(
    reportMessage: ReportMessageDTO
  ): IMessageReport {
    return {
      id: reportMessage.id,
      messageIdHash: reportMessage.message_id_hash,
      messageSubject: reportMessage.message_subject,
      reason: reportMessage.reason,
      reporterEmail: reportMessage.reporter_email,
      createdAt: reportMessage.created_at,
      status: reportMessage.status,
      threadId: reportMessage.thread_id,
    };
  }
  async updateMessageReportStatus(
    mList: string,
    subject: string,
    moderatorDecision: string,
    messageIdHash: string,
    emailTemplate: string,
    moderatorReason?: string
  ): Promise<void> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const csrfToken = this.securityService?.getToken();
    if (csrfToken) {
      headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;
    }
    const response = await fetch(
      `/api/v1/${mList}/message-reports?message_id_hash=${messageIdHash}`,
      {
        method: "PUT",
        headers,
        body: JSON.stringify({
          email_template: emailTemplate,
          message_subject: subject,
          moderator_decision: moderatorDecision,
          moderator_reason: moderatorReason,
        }),
      }
    );
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return data;
  }
}
