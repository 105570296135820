import IBlurImageToggleService from "@/application/IBlurImageToggleService";
import { singleton } from "tsyringe";
import { Observable, BehaviorSubject } from "rxjs";

@singleton()
export default class BlurImageToggleService implements IBlurImageToggleService {
  private showImg$ = new BehaviorSubject(false);
  constructor() {
    const showImg = sessionStorage.getItem("showImg") === "true";
    this.showImg$.next(showImg);
  }
  getShowImg(): Observable<boolean> {
    return this.showImg$.asObservable();
  }

  setShowImg(showImg: boolean): void {
    this.showImg$.next(showImg);
    sessionStorage.setItem("showImg", JSON.stringify(showImg));
  }
}
