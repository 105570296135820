<template>
  <div v-html="sanitizeHtmlContent"></div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Inject } from "vue-property-decorator";
import IMessage from "@/domain/IMessage";
import IMessageService from "@/application/IMessageService";
import SanitizeHtmlContentService from "@/infrastructure/SanitizeHtmlContentService";

@Component({})
export default class HyperkittySearchContent extends Vue {
  @Prop({ required: true }) private listId!: string;
  @Prop({ required: true }) private messageIdHash!: string;
  @Inject("IMessageService") messageService!: IMessageService;
  private message: IMessage | null = null;

  async mounted() {
    this.message = await this.messageService.getMessage(
      this.listId,
      this.messageIdHash
    );
  }

  get sanitizeHtmlContent(): string {
    return SanitizeHtmlContentService.sanitizeHtmlContent(
      String(this.message?.htmlContent || "")
    );
  }
}
</script>
