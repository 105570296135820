<template>
  <div>
    <div
      v-if="replies.length"
      class="single-thread-view__form-show-replies pt-3 pb-3 d-none d-md-block"
    >
      <fieldset class="d-inline align-items-center">
        <legend class="float-left w-auto mb-0 mr-4 ml-2">Show replies:</legend>
        <div class="d-inline mr-4">
          <input
            type="radio"
            id="sort-by-date"
            v-model="sortBy"
            @change="sortReply"
            value="date"
            name="sort"
          />
          <label class="mb-0 ml-1" for="sort-by-date">by date</label>
        </div>
        <div class="d-inline">
          <input
            type="radio"
            v-model="sortBy"
            id="sort-by-thread"
            value="thread_order"
            name="sort"
            checked
            @change="sortReply"
          />
          <label class="mb-0 ml-1" for="sort-by-thread">by thread</label>
        </div>
      </fieldset>
    </div>
    <div v-for="(reply, index) in internalReplies" :key="index">
      <div :class="[...clazz, reply.depthClass]" :id="reply.idHash">
        <div class="message__top p-3 p-md-4">
          <div class="d-flex mb-2">
            <div class="message__avatar d-none d-md-block flex-fill">
              <img
                alt="avatar"
                class="rounded-circle gravatar"
                :src="reply.sender.imageUrl"
                width="40"
                height="40"
              />
            </div>
            <div class="message__content flex-fill">
              <div
                class="d-flex flex-column flex-md-row justify-content-between mb-3"
              >
                <div class="mb-1 mb-md-0">
                  <div v-if="reply.sender.mailmanId">
                    <a
                      :href="`/profile/${reply.sender.mailmanId}`"
                      :title="`See the profile ${reply.sender.name}`"
                    >
                      {{ reply.sender.name }}</a
                    >
                  </div>
                  <div v-else>{{ reply.sender.name }}</div>
                </div>
                <div>
                  {{ dateTimeFormat(reply.date) }}
                </div>
              </div>
              <div>
                <div class="email-body">
                  <MessageContent
                    :html-content="reply.html_content"
                    :img-blur-state="sessionShowImgValue"
                  ></MessageContent>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-start message__activity mt-3">
            <div v-if="user">
              <a
                @click="toggleReply(Number(index))"
                class="reply-form__header reply"
                :data-email-id-hase="reply.idHash"
                href="#"
              >
                <i class="fa fa-reply mr-3"></i><span class="mr-4"> Reply</span>
              </a>
            </div>
            <div v-else>
              <a
                class="reply reply-mailto"
                title="Sign in to reply online"
                :href="'mailto:' + reply.mailingList.name"
              >
                <i class="fa fa-reply mr-3"></i><span class="mr-4"> Reply</span>
              </a>
            </div>
            <VoteView
              :message-id-hash="reply.idHash"
              :mlist-name="reply.mailingList.name"
              :likes="Number(reply.likes)"
              :dislikes="Number(reply.dislikes)"
              :enabled="user && user.id !== reply.sender.mailmanId"
              class="mr-4"
            />
            <ReportPostUserModalView
              :m-list="reply.mailingList.name"
              :id-hash="reply.idHash"
              :flagged-by-moderator="reply.flaggedByModerator"
            />
          </div>
        </div>
        <ReplyMessageSuccess v-if="reply.success"></ReplyMessageSuccess>
        <div class="p-3">
          <NewMessageFormView
            :enabled="internalReplies[index].replyBtn"
            :mlist-name="reply.mailingList.name"
            :user="userEmail"
            :id-hash="reply.idHash"
            :is-reply="true"
            @onSend="onSend"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import IMessageService from "@/application/IMessageService";
import IReply from "@/domain/IReply";
import VoteView from "../views/VoteView.vue";
import NewMessageFormView from "@/views/NewMessageFormView.vue";

import moment from "moment";
import WysiwygEditor from "@/components/WysiwigEditor.vue";
import ISecurityService from "@/application/ISecurityService";
import IUser from "@/domain/IUser";
import SanitizeHtmlContentService from "@/infrastructure/SanitizeHtmlContentService";
import ReplyMessageSuccess from "@/components/ReplyMessageSuccess.vue";
import {
  filter,
  fromEvent,
  scan,
  skip,
  skipWhile,
  startWith,
  takeUntil,
} from "rxjs";
import IProfileService from "@/application/IProfileService";
import IProfileSummary from "@/domain/IProfileSummary";
import IBlurImageToggleService from "@/application/IBlurImageToggleService";
import MessageContent from "@/components/MessageContent.vue";
import ReportPostUserModalView from "@/components/ReportPostUserModalView.vue";

const COLLAPSED_MESSAGE_HEIGHT = 132;
const WINDOW_HEIGHT_SKEW = 264;

interface Reply extends IReply {
  replyBtn: boolean;
  success: boolean;
  depthClass: string;
}

@Component({
  components: {
    WysiwygEditor,
    VoteView,
    ReplyMessageSuccess,
    MessageContent,
    NewMessageFormView,
    ReportPostUserModalView,
  },
})
export default class RepliesListView extends Vue {
  @Inject("ISecurityService") securityService!: ISecurityService;
  @Inject("IMessageService") messageService!: IMessageService;
  @Inject("IProfileService") profileService!: IProfileService;
  @Inject("IBlurImageToggleService")
  blurImageToggleService!: IBlurImageToggleService;
  @Prop({ required: true }) private mList!: string;
  @Prop({ required: true }) mlistFqdn!: string;
  @Prop({ required: true }) mlistId!: string;
  @Prop({ required: true }) private threadId!: number;
  @Prop({ required: true }) private userEmail!: string;

  private replies: Array<IReply> = [];
  private user!: IUser | undefined;
  private clazz = ["message email mb-1"];
  private clickedReplyButtons: Array<number> = [];
  private sortBy!: string;
  private profileSummary: IProfileSummary | null = null;
  sessionShowImgValue: boolean | null = null;

  mounted() {
    this.replyInfiniteScroll();
    this.securityService.getUser().subscribe((user) => {
      this.user = user;
    });
    this.profileService.getProfileSummary().then((profile) => {
      this.profileSummary = profile;
    });
    this.blurImageToggleService.getShowImg().subscribe((showImg: boolean) => {
      this.sessionShowImgValue = showImg;
    });
  }

  private get internalReplies(): Array<Reply> {
    const newReplies: Array<Reply> = [];
    for (let i = 0; i < this.replies.length; i++) {
      newReplies.push({
        ...this.replies[i],
        replyBtn: this.clickedReplyButtons.includes(i),
        success: false,
        depthClass:
          this.sortBy === "thread_order"
            ? "reply-level-" + Number(this.replies[i].threadDepth - 1)
            : "reply-level-0",
      });
    }
    newReplies.forEach((_reply) => {
      if (_reply.threadDepth >= 5) {
        _reply.threadDepth = 5;
      }
    });
    return newReplies;
  }

  private dateTimeFormat(dateTime: string): string {
    return moment.parseZone(dateTime).format("D MMM YYYY h:mm a");
  }

  private toggleReply(index: number) {
    let newClickedReplyButtons = [...this.clickedReplyButtons];
    if (!newClickedReplyButtons.includes(index)) {
      newClickedReplyButtons.push(index);
    } else {
      newClickedReplyButtons = newClickedReplyButtons.filter(
        (value) => value !== index
      );
    }
    this.clickedReplyButtons = newClickedReplyButtons;
  }

  private async onSend(data: {
    idHash: string;
    subject: string | null;
    content: string;
    uuid: string;
    newThread: boolean;
  }) {
    if (data.newThread && !data.subject) {
      return;
    }
    const index = this.internalReplies.findIndex(
      (replies) => replies.idHash === data.idHash
    );
    this.toggleReply(index);
    this.internalReplies[index].success = true;
    await this.messageService.replyMessage(
      data.newThread,
      data.subject ?? "",
      SanitizeHtmlContentService.sanitizeHtmlContent(data.content),
      data.idHash,
      this.mlistFqdn,
      this.internalReplies[index].mailingList.name,
      this.mlistId,
      data.uuid
    );
    this.msgSuccess(index, { content: data.content, uuid: data.uuid });
  }

  private msgSuccess(index: number, data: { content: string; uuid: string }) {
    if (this.internalReplies[index].threadDepth >= 5) {
      this.internalReplies[index].threadDepth = 5;
    }
    this.internalReplies[index].success = false;
    this.messageService.pushReply(
      index,
      {
        ...this.replies[index],
        html_content: data.content,
        threadDepth: this.internalReplies[index].threadDepth + 1,
        sender: {
          mailmanId: this.mlistId,
          name: this.userEmail,
          imageUrl: this.profileSummary!.imageUrl,
        },
      },
      false
    );
  }

  private replyInfiniteScroll(): void {
    const limit = Math.ceil(
      (window.innerHeight - WINDOW_HEIGHT_SKEW) / COLLAPSED_MESSAGE_HEIGHT
    );

    const bottomOfWindow$ = fromEvent(window, "scroll").pipe(
      filter(RepliesListView.isBottomOfWindow)
    );

    const offset$ = bottomOfWindow$.pipe(
      scan((offset) => offset + limit, 0),
      startWith(0)
    );
    const newReplyList$ = this.messageService.getReplyList();
    const completed$ = newReplyList$.pipe(
      skip(1),
      skipWhile((_replies) => _replies.length !== this.replies.length)
    );
    offset$.pipe(takeUntil(completed$)).subscribe((_offset) => {
      //for reply list sort when enver they select by date or thread
      const url = new URL(window.location.href);
      const sortBy = url.searchParams.get("sort_by");
      if (sortBy) {
        this.sortBy = sortBy;
      } else {
        this.sortBy = "thread_order";
      }

      this.messageService.loadMore(
        this.mList,
        this.threadId,
        limit,
        _offset,
        this.sortBy
      );
    });

    newReplyList$.subscribe((_replies) => {
      this.replies = _replies;
    });
  }

  private static isBottomOfWindow(event: Event): boolean {
    const document = event.target as Document;
    const window = event.currentTarget as Window;

    return RepliesListView.isAlmostEqual(
      document.documentElement.scrollTop + window.innerHeight,
      document.documentElement.offsetHeight
    );
  }

  private static isAlmostEqual(a: number, b: number): boolean {
    return Math.abs(a - b) < 1;
  }
  private sortReply() {
    window.location.href = `?sort_by=${this.sortBy}`;
  }
}
</script>
<style scoped lang="scss">
.new-thread {
  display: none;
}

.btn-cancel {
  display: flex;
  left: 25%;
  bottom: 2.4em;
}

.message__content {
  ::v-deep img {
    max-width: 100%;
    height: auto;
  }
}
</style>
