<template>
  <div class="d-flex flex-column align-items-center w-100 mt-5">
    <ProfileSummary :profileSummary="profileSummary" />

    <ProfileDetails :profile="profile" @onSave="saveProfile" />

    <ProfilePostingActivity />

    <ProfilePostingActivityMobile />

    <hr class="mb-5" />

    <SectionIframeContainer
      src="/accounts/email/"
      title="Manage email addresses"
    />

    <ProfileTimeZonePreferenceView />
  </div>
</template>

<script lang="ts">
import { Component, Inject, Vue } from "vue-property-decorator";
import ProfileSummary from "@/components/ProfileSummary.vue";
import ProfileDetails from "@/components/ProfileDetails.vue";
import ProfilePostingActivity from "@/components/ProfilePostingActivity.vue";
import ProfilePostingActivityMobile from "@/components/ProfilePostingActivityMobile.vue";
import SectionIframeContainer from "@/components/SectionIframeContainer.vue";
import IProfileService from "@/application/IProfileService";
import IProfile from "@/domain/IProfile";
import IProfileSummary from "@/domain/IProfileSummary";
import ProfileDeleteAccount from "@/components/ProfileDeleteAccount.vue";
import ProfileDeleteAccountView from "@/views/ProfileDeleteAccountView.vue";
import ProfileTimeZonePreferenceView from "@/views/ProfileTimeZonePreferenceView.vue";
import { from, mergeMap } from "rxjs";
@Component({
  components: {
    ProfileTimeZonePreferenceView,
    ProfileDeleteAccountView,
    ProfileDeleteAccount,
    SectionIframeContainer,
    ProfilePostingActivityMobile,
    ProfilePostingActivity,
    ProfileDetails,
    ProfileSummary,
  },
})
export default class ProfileView extends Vue {
  @Inject("IProfileService") profileService!: IProfileService;

  private profile: IProfile | null = null;
  private profileSummary: IProfileSummary | null = null;

  mounted(): void {
    this.profileService
      .getProfile()
      .then((profile) => (this.profile = profile));

    const $timeZone = this.profileService.getTimeZone();
    const $profileSummary = $timeZone.pipe(
      mergeMap(() => from(this.profileService.getProfileSummary()))
    );
    $profileSummary.subscribe(
      (profileSummary) => (this.profileSummary = profileSummary)
    );
  }

  private async saveProfile(profile: IProfile): Promise<void> {
    this.profile = await this.profileService.save(profile);
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";
hr {
  max-width: $section-max-width;
  border-top: 0.125rem solid $grey-border;
  display: none;
  @media (min-width: $breakpoint-md) {
    display: block;
  }
}
</style>
