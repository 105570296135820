<template>
  <div>
    <LoadingSkeleton v-if="loading" width="100%" height="320px" radius="0" />
    <ProfileSubscriptions v-else :subscriptions="subscriptions" />
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import ProfileSubscriptions from "@/components/ProfileSubscriptions.vue";
import ISubscription from "@/domain/ISubscription";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import IUserService from "@/application/IUserService";
@Component({
  components: { LoadingSkeleton, ProfileSubscriptions },
})
export default class PublicProfileSubscriptionsView extends Vue {
  @Inject("IUserService") userService!: IUserService;

  @Prop({ required: true }) private userId!: string;

  private subscriptions: Array<ISubscription> = [];
  private loading = true;

  private async mounted(): Promise<void> {
    const subscriptions = await this.userService.getSubscriptions(this.userId);
    this.subscriptions = subscriptions.filter(
      (subscription) => subscription.archived
    );
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
ul {
  background-color: #ffffff;
}
</style>
