<template>
  <div v-if="participants.length > 0">
    <h3>Participants ({{ participants.length }})</h3>
    <ul
      class="single-thread-view__thread-stats__participants pb-3 pb-md-0 mb-0"
    >
      <li
        class="d-flex align-items-center mb-2"
        v-for="participant in participants"
        :key="`participant_${participant.mailmanId}`"
      >
        <div
          class="single-thread-view__thread-stats__participants__avatar mr-2"
        >
          <img
            v-if="participant.imageUrl"
            :src="participant.imageUrl"
            class="gravatar"
            width="40"
          />
          <img v-else src="@/assets/img/avatar-sm.svg" />
        </div>
        <a
          :href="`/profile/${participant.mailmanId}`"
          title="See profile"
          v-if="participant.mailmanId"
          >{{ participant.name }}</a
        >
        <span v-else>{{ participant.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { ISender } from "@/domain/ISender";
import IThreadService from "@/application/IThreadService";

@Component
export default class ThreadParticipantsView extends Vue {
  @Inject("IThreadService") threadService!: IThreadService;
  @Prop({ required: true }) private threadIdHash!: string;
  @Prop({ required: true }) private mListFqdn!: string;

  private participants: Array<ISender> = [];

  private async mounted(): Promise<void> {
    const thread = await this.threadService.getThread(
      this.mListFqdn,
      this.threadIdHash
    );

    this.participants = thread.participants.sort((a, b) => {
      const nameA = a.name ?? "";
      const nameB = b.name ?? "";
      return nameA.localeCompare(nameB);
    });
  }
}
</script>
