import ISecurityService from "@/application/ISecurityService";
import { injectable } from "tsyringe";
import { BehaviorSubject, first, mergeMap, Observable, Subject } from "rxjs";
import IUser from "@/domain/IUser";
import { ajax } from "rxjs/internal/ajax/ajax";
import { UserRole } from "@/domain/UserRole";

const CSRF_TOKEN_COOKIE_NAME = "csrftoken";

enum UserRoleDTO {
  SUPERUSER = "SUPERUSER",
  STAFF = "STAFF",
}

type UserDTO = { id?: string; roles: UserRoleDTO[] };

@injectable()
export default class SecurityService implements ISecurityService {
  private user$ = new BehaviorSubject<IUser | undefined>(undefined);
  private userRequestQueue$ = new Subject<void>();

  constructor() {
    this.scheduleUserRequest();
  }

  public getToken(): string | undefined {
    return this.getCookie(CSRF_TOKEN_COOKIE_NAME);
  }

  private getCookie(name: string): string | undefined {
    let cookieValue = undefined;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  public getUser(): Observable<IUser | undefined> {
    this.userRequestQueue$.next();
    return this.user$.asObservable();
  }

  private scheduleUserRequest(): void {
    this.userRequestQueue$
      .pipe(
        first(),
        mergeMap(() => ajax.getJSON<UserDTO>(`/api/v1/users/me`))
      )
      .subscribe((userDto) => {
        this.user$.next(SecurityService.toIUser(userDto));
      });
  }

  private static toIUser(userDto: UserDTO): IUser {
    const roles: UserRole[] = userDto.roles.map((r) => {
      switch (r) {
        case UserRoleDTO.SUPERUSER:
          return UserRole.SUPERUSER;

        case UserRoleDTO.STAFF:
          return UserRole.SUPERUSER;
      }
    });

    return {
      id: userDto.id,
      roles: roles,
    };
  }
}
