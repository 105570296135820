<template>
  <div class="new-thread">
    <div v-if="errorResponse" class="container-fluid mt-2 mb-2">
      <div class="content-msg">
        This content is blocked by Cloudflare. Please try again on another
        device
      </div>
    </div>
    <section class="d-flex flex-column mx-auto">
      <div class="message-header">
        <h4>Create a new thread</h4>
      </div>
      <div class="new-thread-form">
        <label class="subject-label" for="formGroupSubject">Subject</label>

        <NewMessageFormView
          :enabled="true"
          :is-reply="false"
          :user="user"
          :mlist-name="mlistName"
          @onSend="sendNewMessage"
        />
        <div class="col-3 btn-cancel">
          <a class="btn" :href="'/mailing-list/' + mlistName">Cancel</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Inject } from "vue-property-decorator";
import WysiwygEditor from "@/components/WysiwigEditor.vue";
import IMessageService from "@/application/IMessageService";
import SanitizeHtmlContentService from "@/infrastructure/SanitizeHtmlContentService";
import NewMessageFormView from "@/views/NewMessageFormView.vue";
import DOMPurify from "dompurify";

type Response = {
  responseStatus: number;
  server: string;
} | void;
@Component({
  components: { NewMessageFormView, WysiwygEditor },
})
export default class NewMessageView extends Vue {
  @Inject("IMessageService") messageService!: IMessageService;
  @Prop({ required: true }) mlistName!: string;
  @Prop({ required: true }) user!: string;
  private response!: Response;
  private errorContent!: string;
  private errorResponse = false;

  private async sendNewMessage(data: {
    subject: string;
    content: string;
    uuid: string;
  }) {
    if (data.subject) {
      this.response = await this.messageService.sendMessage(
        this.sanitizeSubject(data.subject),
        this.mlistName,
        SanitizeHtmlContentService.sanitizeHtmlContent(data.content),
        data.uuid
      );
      if (
        this.response?.responseStatus === 403 &&
        this.response?.server === "cloudflare"
      ) {
        this.errorResponse = true;
      }
    }
  }

  // sanitize subject content
  private sanitizeSubject(subject: string): string {
    return DOMPurify().sanitize(subject);
  }
}
</script>
<style scoped lang="scss">
.btn-cancel {
  display: flex;
  left: 25%;
  bottom: 2.4em;
}
.message-header {
  margin-top: 24px;
}
.subject-label {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2px;
}

.container-fluid {
  height: 45px;
  max-height: 45px;
  max-width: 100%;
  background-color: #b00020;
  color: white;
  padding: 10px;
}
.content-msg {
  margin-left: 10px;
}
</style>
