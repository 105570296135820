<template>
  <div class="container-fluid mt-2 mb-2">
    <div class="content-msg">
      {{ msgSuccess }}
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class ReplyMessageSuccess extends Vue {
  private msgSuccess = "Your reply has been sent and is being processed.";
}
</script>

<style scoped lang="scss">
.container-fluid {
  height: 45px;
  max-height: 45px;
  max-width: 100%;
  background-color: green;
  color: white;
  padding: 10px;
}
.content-msg {
  margin-left: 10px;
}
</style>
