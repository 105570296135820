<template>
  <div class="list-group mt-4 mt-md-1">
    <div v-if="loading">
      <LoadingSkeleton width="100%" radius="0" />
    </div>
    <div v-else-if="threads.length > 0" class="overview-thread-list">
      <div :key="key" v-for="(thread, key) in threads">
        <div class="list-group-item list-group-item-action p-2">
          <div class="row">
            <div class="col-auto ml-2 pr-2">
              <i class="fa fa-envelope text-secondary"></i>
            </div>
            <div class="col p-1">
              <div class="list-group-item-heading mb-1">
                <a
                  :href="`/hyperkitty/list/${thread.mailingList.name}/thread/${thread.threadId}`"
                >
                  <h6 class="text-break m-0 text-dark">
                    {{ thread.subject }}
                  </h6>
                </a>
              </div>
              <div v-if="thread.sender.mailmanId">
                <a :href="`/profile/${thread.sender.mailmanId}`">
                  by {{ thread.sender.name }}</a
                >
              </div>
              <div v-else>by {{ thread.sender.name }}</div>
              <div class="list-group-item-text d-md-block d-none">
                <span>
                  {{ formatDate(thread.dateActive) }}
                </span>
                <div class="d-flex list-stats justify-content-start mt-2">
                  <div class="hidden-tn">
                    <vote-view
                      class="mr-4"
                      :message-id-hash="thread.idHash"
                      :mlist-name="thread.mailingList.name"
                      :likes="thread.likes"
                      :dislikes="thread.dislikes"
                      :enabled="user && user.id !== thread.sender.mailmanId"
                    ></vote-view>
                  </div>

                  <div class="mr-4">
                    <span class="mr-3">
                      <i class="fa fa-user text-muted"></i>
                      {{ thread.participantsCount }}
                    </span>
                    <span>
                      <i class="fa fa-comment text-muted"></i>
                      {{ replyCount(thread.emailsCount) }}
                    </span>
                  </div>
                  <report-post-user-modal-view />
                </div>
              </div>
              <div class="list-group-item-text d-md-none d-sm-block mr-3">
                {{ formatDate(thread.dateActive) }}
                <div
                  class="row pull-right list-stats justify-content-between mt-2 mb-n1"
                >
                  <div class="hidden-tn col-6">
                    <vote-view
                      :message-id-hash="thread.idHash"
                      :mlist-name="thread.mailingList.name"
                      :likes="thread.likes"
                      :dislikes="thread.dislikes"
                      :enabled="user && user.id !== thread.sender.mailmanId"
                    ></vote-view>
                  </div>

                  <div class="col-6">
                    <span class="mr-3">
                      <i class="fa fa-user text-muted"></i>
                      {{ thread.participantsCount }}
                    </span>
                    <span>
                      <i class="fa fa-comment text-muted"></i>
                      {{ replyCount(thread.emailsCount) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="overview-thread-list">
      <div class="empty-overview-thread-list">
        <div v-if="selectedTab === 'flagged'" class="empty-state-content">
          <div class="empty-state-feature-icon">
            <i class="fa fa-fw fa-star-o"></i>
          </div>
          <div class="empty-text">No favorites</div>
          <div class="empty-supporting-text">
            {{
              `You have not favourited any discussions in the ${mlistDisplayName} list yet. `
            }}
          </div>
          <div>
            <button @click="returnRecentTab" class="empty-button">
              Browse recent posts
            </button>
          </div>
        </div>
        <div
          v-else-if="selectedTab === 'posted-to'"
          class="empty-state-content"
        >
          <div class="empty-state-feature-icon">
            <i class="fa fa-pencil"></i>
          </div>
          <div class="empty-text">No post yet</div>
          <div class="empty-supporting-text">
            {{ `You have not yet written a post in ${mlistDisplayName} ` }}
          </div>
          <div>
            <a :href="`/hyperkitty/list/${mlist}/message/new`">
              <button class="empty-button">Start a new thread</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import IThread from "@/domain/IThread";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import VoteView from "@/views/VoteView.vue";
import moment from "moment";
import { concatMap, filter, fromEvent, scan, startWith, takeWhile } from "rxjs";
import IUser from "@/domain/IUser";

const COLLAPSED_MESSAGE_HEIGHT = 132;
const WINDOW_HEIGHT_SKEW = 264;

@Component({
  components: { LoadingSkeleton, VoteView },
})
export default class OverviewThreadContentList extends Vue {
  @Prop({ required: true }) mlist!: string;
  @Prop({ required: false }) mlistDisplayName!: string;
  @Prop({ required: true }) selectedTab!: string;
  @Prop({ required: true }) user!: IUser;
  @Prop({ required: true }) loadMore!: (
    mlist: string,
    limit: number,
    offset: number
  ) => Promise<IThread[]>;
  private threads: Array<IThread> = [];
  private loading = true;

  mounted() {
    this.mailingListThreadInfiniteScroll();
  }

  public formatDate(date: string) {
    return moment.parseZone(date).format("D MMM YYYY h:mm a");
  }

  private mailingListThreadInfiniteScroll(): void {
    const limit = Math.ceil(
      (window.innerHeight - WINDOW_HEIGHT_SKEW) / COLLAPSED_MESSAGE_HEIGHT
    );
    const bottomOfWindow$ = fromEvent(window, "scroll").pipe(
      filter(OverviewThreadContentList.isBottomOfWindow)
    );

    const offset$ = bottomOfWindow$.pipe(
      scan((offset) => offset + limit, 0),
      startWith(0)
    );

    const moreThreads$ = offset$.pipe(
      concatMap((offset) => this.loadMore(this.mlist, limit, offset)),
      takeWhile((moreThreads) => moreThreads.length > 0)
    );
    moreThreads$.subscribe({
      next: (moreThreads) => {
        this.threads = [...this.threads, ...moreThreads];
        this.loading = false;
      },
      complete: () => (this.loading = false),
    });
  }

  private static isBottomOfWindow(event: Event): boolean {
    const document = event.target as Document;
    const window = event.currentTarget as Window;

    return OverviewThreadContentList.isAlmostEqual(
      document.documentElement.scrollTop + window.innerHeight,
      document.documentElement.offsetHeight
    );
  }

  private static isAlmostEqual(a: number, b: number): boolean {
    return Math.abs(a - b) < 1;
  }

  private returnRecentTab() {
    this.$emit("returnRecentTab");
  }

  //Each thread has a number of emails from which one is the starting email and
  // the rest are replies that's we - 1 the thread.emailsCount.
  private replyCount(emailCount: number): number {
    return emailCount - 1;
  }
}
</script>

<style scoped lang="scss">
.empty-overview-thread-list {
  display: flex;
  max-width: 100%;
  height: 322px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.empty-state-container {
  display: flex;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}
.empty-state-content {
  display: flex;
  width: 352px;
  flex-direction: column;
  align-items: center;
}
.empty-state-feature-icon {
  display: flex;
  color: #006aa4;
  width: 48px;
  height: 48px;
  padding: 12px;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 8px solid var(--Primary-50, #f4f9fc);
  background: var(--Primary-100, #e8f3f9);
}
.empty-text {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}
.empty-supporting-text {
  color: var(--Gray-500, #808080);
  text-align: center;
  /* Text sm/Regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: 20px; /* 142.857% */
  margin-bottom: 24px;
}
.empty-button {
  border: 1px solid var(--Gray-300, #ccc);
  background: var(--Base-White, #fff);
  /* Shadow/xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
</style>
