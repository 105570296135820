<template>
  <li class="nav-item">
    <LoadingSkeleton
      v-if="loading"
      width="100%"
      height="2rem"
      radius="0"
      background-color="#333333"
    />
    <MySubscriptions
      v-else
      :subscriptions="subscriptions"
      :currentUrlPath="currentUrlPath"
      v-model="expanded"
    />
  </li>
</template>

<script lang="ts">
import MySubscriptions from "@/components/MySubscriptions.vue";
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import ISubscription from "@/domain/ISubscription";
import IProfileService from "@/application/IProfileService";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";

const SESSION_STORAGE_MY_SUBSCRIPTIONS_KEY = "_my_subscriptions_items_expanded";

@Component({ components: { MySubscriptions, LoadingSkeleton } })
export default class MySubscriptionsView extends Vue {
  @Inject("IProfileService") profileService!: IProfileService;

  private loading = true;
  private expanded = false;
  private currentUrlPath = "";
  private subscriptions: Array<ISubscription> = [];

  private async mounted(): Promise<void> {
    this.profileService.getSubscriptions().subscribe((subscriptions) => {
      if (subscriptions) {
        this.subscriptions = subscriptions.filter(
          (subscription) => subscription.archived
        );
        this.loading = false;
      }
    });

    this.currentUrlPath = location.pathname;
    this.initMySubscriptionState();
  }

  private initMySubscriptionState(): void {
    if (
      sessionStorage.getItem(SESSION_STORAGE_MY_SUBSCRIPTIONS_KEY) === "true"
    ) {
      this.expanded = true;
    } else {
      this.expanded = false;
    }

    const wrapper = document.getElementById("wrapper") as HTMLElement;
    if (!wrapper.classList.contains("toggled")) {
      this.expanded = false;
    }
  }

  @Watch("expanded")
  private onExpandedChange(value: boolean): void {
    if (value) {
      const wrapper = document.getElementById("wrapper") as HTMLElement;
      wrapper.classList.add("toggled");
    }

    sessionStorage.setItem(
      SESSION_STORAGE_MY_SUBSCRIPTIONS_KEY,
      value.toString()
    );
  }
}
</script>
