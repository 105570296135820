import { container } from "tsyringe";
import ProfileService from "@/infrastructure/ProfileService";
import ThreadService from "@/infrastructure/ThreadService";
import SecurityService from "@/infrastructure/SecurityService";
import UserService from "@/infrastructure/UserService";
import MobileMenuService from "@/infrastructure/MobileMenuService";
import VoteService from "./infrastructure/VoteService";
import TimeZoneService from "@/infrastructure/TimeZoneService";
import MessageService from "@/infrastructure/MessageService";
import BlurImageToggleService from "@/infrastructure/BlurImageToggleService";
import SidebarStateService from "@/infrastructure/SidebarStateService";
import FileUploadService from "@/infrastructure/FileUploadService";
import ConfigurationService from "@/infrastructure/ConfigurationService";
import MetricsService from "@/infrastructure/MetricsService";
import MessageReportService from "@/infrastructure/MessageReportService";

container.registerSingleton("IProfileService", ProfileService);
container.registerSingleton("IUserService", UserService);
container.registerSingleton("IThreadService", ThreadService);
container.registerSingleton("IMessageService", MessageService);
container.registerSingleton("ISecurityService", SecurityService);
container.registerSingleton("IMobileMenuService", MobileMenuService);
container.registerSingleton("IVoteService", VoteService);
container.registerSingleton("ITimeZoneService", TimeZoneService);
container.registerSingleton("IBlurImageToggleService", BlurImageToggleService);
container.registerSingleton("ISideBarStateService", SidebarStateService);
container.registerSingleton("IFileUploadService", FileUploadService);
container.registerSingleton("IConfigurationService", ConfigurationService);
container.registerSingleton("IConfigurationService", ConfigurationService);
container.registerSingleton("IMetricsService", MetricsService);
container.registerSingleton("IMessageReportService", MessageReportService);
