<template>
  <div class="card mb-md-5">
    <div class="card-header p-0" id="heading_delete_account">
      <h2 class="mb-0">
        <button
          class="btn btn-link btn-block text-left d-flex d-md-block justify-content-between collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#collapse_delete_account"
          aria-expanded="true"
          aria-controls="#collapse_delete_account"
        >
          <span class="mr-0 mr-md-2">Delete Orbit Account</span>
          <i class="fas fa-angle-up"></i>
        </button>
      </h2>
    </div>

    <div
      id="collapse_delete_account"
      class="collapse"
      aria-labelledby="heading_delete_account"
    >
      <div class="card-body p-3">
        <ProfileDeleteAccount @onDelete="onDelete" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Vue } from "vue-property-decorator";
import ProfileDeleteAccount from "@/components/ProfileDeleteAccount.vue";
import IProfileService from "@/application/IProfileService";
@Component({
  components: { ProfileDeleteAccount },
})
export default class SectionIframeContainer extends Vue {
  @Inject("IProfileService") profileService!: IProfileService;

  private async onDelete(): Promise<void> {
    await this.profileService.deleteAccount();
    document.location.href = "/";
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.card {
  max-width: $section-max-width;
  width: 100%;
  box-shadow: none;
  border: 0;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2),
    0px 1px 3px rgba(50, 50, 71, 0.1);
  .card-header {
    background-color: #ffffff;

    button {
      text-decoration: none;
      i {
        transition-duration: 500ms;
        transition-property: transform;
      }
      &.collapsed {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }

  iframe {
    width: 100%;
    min-height: 40vh;
    border-style: none;
  }
}
</style>
