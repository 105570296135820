<template>
  <div>
    <div class="d-flex d-md-none col-12 my-4">
      <div class="align-self-center mr-2">
        <span class="text-nowrap">Filter by:</span>
      </div>
      <div class="w-100">
        <select
          class="form-select custom-select"
          aria-label="Filter"
          v-model="selectedTab"
        >
          <option v-for="tab in tabs" :key="tab.id" :value="tab.id">
            {{ tab.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="discussions col-12">
      <ul class="nav nav-pills d-none d-md-flex row text-center" role="tablist">
        <li
          class="nav-item col"
          v-for="tab in tabs"
          :key="tab.id"
          @click="changeTab(tab.id)"
        >
          <a
            :href="`#${tab.id}`"
            :aria-controls="tab.id"
            role="tab"
            :class="{ 'nav-link': true, active: selectedTab === tab.id }"
          >
            {{ tab.label }}
          </a>
        </li>
      </ul>
    </div>

    <div class="tab-content discussions pull-right col-12">
      <div
        id="most-recent"
        role="tabpanel"
        :class="{ 'tab-pane': true, active: selectedTab === 'most-recent' }"
      >
        <OverviewThreadContentList
          v-if="selectedTab === 'most-recent'"
          :load-more="getRecentThreads"
          :mlist="mlist"
          :selected-tab="selectedTab"
          :user="user"
        />
      </div>
      <div
        id="most-active"
        role="tabpanel"
        :class="{ 'tab-pane': true, active: selectedTab === 'most-active' }"
      >
        <OverviewThreadContentList
          v-if="selectedTab === 'most-active'"
          :load-more="getTopThreads"
          :mlist="mlist"
          :selected-tab="selectedTab"
          :user="user"
        />
      </div>
      <div
        id="most-upvote"
        role="tabpanel"
        :class="{ 'tab-pane': true, active: selectedTab === 'most-upvote' }"
      >
        <OverviewThreadContentList
          v-if="selectedTab === 'most-upvote'"
          :load-more="getMostUpvoteThreads"
          :mlist="mlist"
          :selected-tab="selectedTab"
          :user="user"
        />
      </div>
      <div
        id="flagged"
        role="tabpanel"
        :class="{ 'tab-pane': true, active: selectedTab === 'flagged' }"
      >
        <OverviewThreadContentList
          v-if="selectedTab === 'flagged'"
          :load-more="getFavoriteThreads"
          :mlist="mlist"
          :selected-tab="selectedTab"
          :user="user"
          :mlist-display-name="mlistDisplayName"
          @returnRecentTab="returnRecentTab"
        />
      </div>
      <div
        id="posted-to"
        role="tabpanel"
        :class="{ 'tab-pane': true, active: selectedTab === 'posted-to' }"
      >
        <OverviewThreadContentList
          v-if="selectedTab === 'posted-to'"
          :load-more="getMyPostedThreads"
          :mlist="mlist"
          :selected-tab="selectedTab"
          :user="user"
          :mlist-display-name="mlistDisplayName"
        />
      </div>
    </div>
    <!-- /container, for thread lists -->
  </div>
</template>

<script lang="ts">
import ISecurityService from "@/application/ISecurityService";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import OverviewThreadContentList from "@/components/OverviewThreadContentList.vue";

import IThreadService from "@/application/IThreadService";
import IThread from "@/domain/IThread";
import IUser from "@/domain/IUser";

const TAB_OPTIONS_USER_NOT_LOG_IN = [
  {
    id: "most-recent",
    label: "Recent",
    active: true,
  },
  {
    id: "most-active",
    label: "Most Active",
    active: false,
  },
  {
    id: "most-upvote",
    label: "Most Upvotes",
    active: false,
  },
];

const TAB_OPTIONS_USER_LOG_IN = [
  ...TAB_OPTIONS_USER_NOT_LOG_IN,
  {
    id: "flagged",
    label: "My Favorites",
    active: false,
  },
  {
    id: "posted-to",
    label: "My Posted",
    active: false,
  },
];

type Tab = {
  id: string;
  label: string;
  active: boolean;
};

@Component({
  components: { OverviewThreadContentList },
})
export default class OverviewThreadListView extends Vue {
  @Inject("ISecurityService") securityService!: ISecurityService;
  @Inject("IThreadService") threadService!: IThreadService;
  @Prop({ required: true }) mlist!: string;
  @Prop({ required: true }) mlistDisplayName!: string;
  private user: IUser | null = null;
  selectedTab = "most-recent";

  private mounted() {
    this.securityService.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  private get tabs(): Tab[] {
    return this.user ? TAB_OPTIONS_USER_LOG_IN : TAB_OPTIONS_USER_NOT_LOG_IN;
  }

  private changeTab(selectedTab: string) {
    this.selectedTab = selectedTab;
  }
  private returnRecentTab() {
    this.selectedTab = "most-recent";
  }
  private async getRecentThreads(
    mlist: string,
    limit: number,
    offset: number
  ): Promise<Array<IThread>> {
    return this.threadService.getRecentThreads(mlist, limit, offset);
  }
  private async getTopThreads(
    mlist: string,
    limit: number,
    offset: number
  ): Promise<Array<IThread>> {
    return this.threadService.getTopThreads(mlist, limit, offset);
  }
  private async getMostUpvoteThreads(
    mlist: string,
    limit: number,
    offset: number
  ): Promise<Array<IThread>> {
    return this.threadService.getMostUpvoteThreads(mlist, limit, offset);
  }

  private async getFavoriteThreads(
    mlist: string,
    limit: number,
    offset: number
  ): Promise<Array<IThread>> {
    return this.threadService.getFavorites(mlist, limit, offset);
  }

  private async getMyPostedThreads(
    mlist: string,
    limit: number,
    offset: number
  ): Promise<Array<IThread>> {
    return this.threadService.getPostedTo(mlist, limit, offset);
  }
}
</script>
