<template>
  <div class="reply-form" v-if="enabled">
    <div
      class="d-flex flex-column-reverse flex-md-row justify-content-between align-items-start align-items-md-center mb-2"
    >
      <div v-if="isReply" class="align-items-center">
        <div class="checkbox">
          <input
            class="mr-2"
            type="checkbox"
            name="checkbox"
            id="id_checkbox"
            v-model="newThread"
          />
          <label class="mb-0"> Create new thread</label>
        </div>
      </div>
      <div v-if="isReply" class="d-flex mb-2 mb-md-0">
        <div>
          <a
            :href="'mailto:' + mlistName"
            class="btn btn-sm btn-outline-secondary pl-2 pr-2 pt-1 pb-1"
          >
            Use email software</a
          >
        </div>
      </div>
    </div>
    <div v-if="newThread || !isReply" class="form-group">
      <input
        type="text"
        name="subject"
        placeholder="New subject"
        :class="subjectInputClass"
        v-model="subject"
      />
      <div :class="subjectValidatorClass">This field is required.</div>
    </div>
    <WysiwygEditor :user="user" @onSend="onSend" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import WysiwygEditor from "@/components/WysiwigEditor.vue";

@Component({
  components: { WysiwygEditor },
})
export default class NewMessageFormView extends Vue {
  @Prop({ required: true }) private enabled = false;
  @Prop({ required: true }) private mlistName!: string;
  @Prop({ required: true }) private user!: string;
  @Prop({ required: true }) isReply!: boolean;
  @Prop({ required: false }) idHash!: string;
  private newThread = false;
  private subject: string | null = null;

  private get subjectInputClass(): string[] {
    return !this.subject ? ["is-invalid", "form-control"] : ["form-control"];
  }

  private get subjectValidatorClass(): string {
    return !this.subject ? "subject-empty" : "subject-valid";
  }

  private toggleReply() {
    this.newThread = !this.newThread;
  }
  private onSend(data: {
    subject: string | null;
    content: string;
    uuid: string;
    newThread: boolean;
    idHash: string;
  }) {
    this.$emit("onSend", {
      subject: this.subject,
      content: data.content,
      uuid: data.uuid,
      newThread: this.newThread,
      idHash: this.idHash,
    });
  }
}
</script>

<style scoped lang="scss">
.subject-empty {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.subject-valid {
  display: none;
}
</style>
