<template>
  <span>{{ displayableText }}</span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class PlainTextView extends Vue {
  @Prop({ required: true }) private text!: string;

  private get displayableText(): string {
    return decodeURIComponent(this.text);
  }
}
</script>
