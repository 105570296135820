import NewsFeedView from "@/views/NewsFeedView.vue";
import ProfileView from "@/views/ProfileView.vue";
import PublicProfileView from "@/views/PublicProfileView.vue";
import SidebarView from "@/views/SidebarView.vue";
import MobileNavbarView from "@/views/MobileNavbarView.vue";
import VoteView from "@/views/VoteView.vue";
import OverviewThreadListView from "@/views/OverviewThreadListView.vue";
import PaginationView from "@/views/PaginationView.vue";
import MySubscriptionsView from "@/views/MySubscriptionsView.vue";
import ManageListsView from "@/views/ManageListsView.vue";
import MessageSenderView from "@/views/MessageSenderView.vue";
import SectionTitleView from "@/views/SectionTitleView.vue";
import BlurImageToggle from "@/components/BlurImageToggle.vue";
import ThreadMessageView from "@/views/ThreadMessageView.vue";
import ReplyMessageView from "@/views/ReplyMessageView.vue";
import WrapperView from "@/views/WrapperView.vue";
import ThreadParticipantsView from "@/views/ThreadParticipantsView.vue";
import PlainTextView from "@/views/PlainTextView.vue";
import NewMessageView from "@/views/NewMessage.vue";
import ReplyListView from "@/views/ReplyListView.vue";
import FooterView from "@/views/FooterView.vue";
import ThreadListSelectOption from "@/components/ThreadListSelectOption.vue";
import MetricsView from "@/views/MetricsView.vue";

import ReportPostUserModalView from "@/components/ReportPostUserModalView.vue";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import ReportPostModeratorView from "@/views/ReportPostModeratorView.vue";
import ReportPostMessageCounter from "@/components/ReportPostMessageCounter.vue";
import HyperkittySearchContent from "@/components/HyperkittySearchContent.vue";

import IThreadService from "@/application/IThreadService";
import IProfileService from "@/application/IProfileService";
import IUserService from "@/application/IUserService";
import { IMobileMenuService } from "@/application/IMobileMenuService";
import IVoteService from "./application/IVoteService";
import ISecurityService from "@/application/ISecurityService";
import ITimeZoneService from "@/application/ITimeZoneService";
import IMessageService from "@/application/IMessageService";
import IBlurImageToggleService from "@/application/IBlurImageToggleService";
import ISidebarStateService from "@/application/ISidebarStateService";
import IFileUploadService from "@/application/IFileUploadService";
import IConfigurationService from "@/application/IConfigurationService";
import IMetricsService from "@/application/IMetricsService";
import IMessageReportService from "@/application/IMessageReportService";

import Vue from "vue/dist/vue.common";
import "reflect-metadata";
import { container } from "tsyringe";

import "./registry";

Vue.config.productionTip = false;

new Vue({
  components: {
    NewsFeedView,
    ProfileView,
    PublicProfileView,
    SidebarView,
    MobileNavbarView,
    VoteView,
    OverviewThreadListView,
    PaginationView,
    MySubscriptionsView,
    ManageListsView,
    MessageSenderView,
    ThreadParticipantsView,
    PlainTextView,
    SectionTitleView,
    BlurImageToggle,
    ThreadMessageView,
    ReplyMessageView,
    WrapperView,
    NewMessageView,
    ReplyListView,
    FooterView,
    ThreadListSelectOption,
    MetricsView,
    ReportPostModeratorView,
    ReportPostUserModalView,
    ReportPostMessageCounter,
    LoadingSkeleton,
    HyperkittySearchContent,
  },
  provide: () => ({
    IThreadService: container.resolve<IThreadService>("IThreadService"),
    IMessageService: container.resolve<IMessageService>("IMessageService"),
    IProfileService: container.resolve<IProfileService>("IProfileService"),
    IUserService: container.resolve<IUserService>("IUserService"),
    IMobileMenuService:
      container.resolve<IMobileMenuService>("IMobileMenuService"),
    IVoteService: container.resolve<IVoteService>("IVoteService"),
    ITimeZoneService: container.resolve<ITimeZoneService>("ITimeZoneService"),
    ISecurityService: container.resolve<ISecurityService>("ISecurityService"),
    IBlurImageToggleService: container.resolve<IBlurImageToggleService>(
      "IBlurImageToggleService"
    ),
    ISidebarStateService: container.resolve<ISidebarStateService>(
      "ISideBarStateService"
    ),
    IFileUploadService:
      container.resolve<IFileUploadService>("IFileUploadService"),

    IConfigurationService: container.resolve<IConfigurationService>(
      "IConfigurationService"
    ),
    IMetricsService: container.resolve<IMetricsService>("IMetricsService"),
    IMessageReportService: container.resolve<IMessageReportService>(
      "IMessageReportService"
    ),
  }),
}).$mount("#app");
