<template>
  <div>
    <div class="modal_confirmation">
      <div class="modal_confirmation__success_report_content">
        <span class="modal_confirmation__success_report_content__check-icon"
          ><i class="fa fa-check-circle mb-3" aria-hidden="true"></i
        ></span>
        <div
          :class="
            confirmationRemoved
              ? 'modal_confirmation__success_report_content__support-text'
              : 'modal_confirmation__success_report_content__support-text-keep-post'
          "
        >
          <p
            class="modal_confirmation__success_report_content__support-text__reported"
            v-if="confirmationRemoved"
          >
            This post has been removed.
          </p>
          <p
            class="modal_confirmation__success_report_content__support-text__reported"
            v-else
          >
            This post has been kept no notification have been sent.
          </p>
          <span
            class="modal_confirmation__success_report_content__support-text__thank_you_text"
            v-if="confirmationRemoved"
          >
            The original post sender has also been notified via email.
          </span>
          <span
            class="modal_confirmation__success_report_content__support-text__thank_you_text"
            v-else
          >
            The reporter of the message has also been notified via email.
          </span>
        </div>
        <div class="modal_confirmation__success_report_content__actions">
          <button class="btn-primary" @click="onClickOk">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ReportPostModalConfirmation extends Vue {
  @Prop({ required: true }) confirmationRemoved!: boolean;

  private onClickOk() {
    this.$emit("onClickOk");
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";
.modal_confirmation {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 3001 !important;
  background-color: rgba(0, 0, 0, 0.5);
  &__success_report_content {
    position: relative;
    margin: 15% auto;
    padding: 20px;
    width: 80%;
    max-width: 400px;
    height: 244px;
    background-color: #fff;
    border-radius: 2px;
    z-index: 3001 !important;
    @media (max-width: $breakpoint-md) {
      width: 80%;
      max-width: 400px;
      height: auto;
      max-height: 300px;
      margin-top: 50%;
    }
    &__check-icon {
      color: #208836;
      margin-bottom: 20px;
    }
    &__support-text {
      margin-bottom: 32px;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 20px;
      }

      &__reported {
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        margin-bottom: 8px;
      }
      &__thank_you_text {
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #808080;
      }
    }
    &__support-text-keep-post {
      margin-bottom: 15px;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 10px;
      }

      &__reported {
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        margin-bottom: 8px;
      }
      &__thank_you_text {
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #808080;
      }
    }
    &__actions {
      display: flex;
      button {
        border: 1px;
        width: 352px;
        height: 44px;
      }
    }
  }
}
</style>
