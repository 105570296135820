import IMetricsService from "@/application/IMetricsService";
import { singleton } from "tsyringe";

import FetchUtils from "@/infrastructure/FetchUtils";
import { IMetric } from "@/domain/IMetric";
import { IMailingListCreatedMetric } from "@/domain/IMailingListCreatedMetric";

const DEFAULT_HTTP_HEADERS = {};

type MetricsDTO = {
  year: number;
  month: number;
  count: number;
};

type MailingListCreatedMetricDTO = {
  name: string;
  displayName: string;
  description: string;
  year: number;
  month: number;
};

function formatDate(date: Date): string {
  return date.toLocaleDateString("sv-SE").substring(0, 10);
}

@singleton()
export default class MetricsService implements IMetricsService {
  async getNewOrbitUsersMetrics(startDate: Date): Promise<Array<IMetric>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch(
      `/api/v1/metrics/new-orbit-users/?start_date=${formatDate(startDate)}`,
      {
        headers,
      }
    );
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return data as Array<MetricsDTO>;
  }

  async getPostsThroughOrbitMetrics(startDate: Date): Promise<Array<IMetric>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch(
      `/api/v1/metrics/posts-through-orbit/?start_date=${formatDate(
        startDate
      )}`,
      {
        headers,
      }
    );
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return data as Array<MetricsDTO>;
  }

  async getNewMailingListsMetrics(
    startDate: Date
  ): Promise<Array<IMailingListCreatedMetric>> {
    const response = await fetch(
      `/api/v1/metrics/new-mailing-lists/?start_date=${formatDate(startDate)}`,
      {}
    );
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return data as Array<MailingListCreatedMetricDTO>;
  }

  async getNewPostsMetrics(
    excludedLists: string[],
    excludePrivate: boolean,
    startDate: Date
  ): Promise<Array<IMetric>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };

    const response = await fetch(
      `/api/v1/metrics/new-posts?exclude_list=${excludedLists}&exclude_private=${excludePrivate}&start_date=${formatDate(
        startDate
      )}`,
      {
        headers,
      }
    );
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return data as Array<MetricsDTO>;
  }

  async getAllNewUsersMetrics(startDate: Date): Promise<Array<IMetric>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };

    const response = await fetch(
      `/api/v1/metrics/all-new-users/?start_date=${formatDate(startDate)}`,
      {
        headers,
      }
    );
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return data as Array<MetricsDTO>;
  }
}
