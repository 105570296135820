<template>
  <div class="row mt-3 mb-2 align-items-center">
    <div class="col-2 col-md-1">
      <span> Year:</span>
    </div>
    <div class="col-10 col-md-3">
      <select
        v-model="year"
        class="form-select custom-select"
        id="year-select-filter"
        aria-label="Year"
        @change="onYearChanged"
      >
        <option v-for="year in yearsOptions" :value="year" :key="year">
          {{ year }}
        </option>
      </select>
    </div>
    <div class="col-2 col-md-1">
      <span> Month: </span>
    </div>
    <div class="col-10 col-md-3">
      <select
        class="form-select custom-select"
        id="month-select-filter"
        aria-label="Month"
        v-model="month"
        @change="onMonthChanged"
      >
        <option
          v-for="month in monthsOptions"
          :key="month.month"
          :value="month.month"
        >
          {{ month.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

type YearMonthObject = {
  year: number;
  month: number[];
};

const monthNames: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MAILING_LIST_PATH = "/mailing-list";

@Component
export default class ThreadListSelectOption extends Vue {
  @Prop({ required: true }) yearMonthList: YearMonthObject[] = [];
  @Prop({ required: true }) year!: number;
  @Prop({ required: true }) month!: number | null;
  @Prop({ required: true }) latestView!: boolean;
  @Prop({ required: true }) mlistName!: string;

  private get yearsOptions(): number[] {
    return this.yearMonthList.map((year) => year.year);
  }

  private get monthsOptions(): Array<{ month: number; name: string }> {
    const monthList = this.yearMonthList.find(
      (monthList) => monthList.year === this.year
    );
    const sortedMonths = monthList?.month.sort((a, b) => b - a) ?? [];
    const monthOptions = sortedMonths.map((month) => {
      return {
        month: month,
        name: monthNames[month - 1],
      };
    });
    monthOptions.unshift({ month: 0, name: "Select a month" });
    if (this.latestView) {
      this.month = 0;
    }
    return monthOptions;
  }

  private onYearChanged(): void {
    this.month = 0;
  }
  private onMonthChanged(): void {
    window.location.href = `${MAILING_LIST_PATH}/${this.mlistName}/${this.year}/${this.month}`;
  }
}
</script>
