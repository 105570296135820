import IConfigurationService from "@/application/IConfigurationService";
import IConfiguration from "@/domain/IConfiguration";
import { singleton } from "tsyringe";

type EditorKeyDTO = {
  editor_key: string;
};

const DEFAULT_HTTP_HEADERS = {
  "Content-Type": "application/json",
};

@singleton()
export default class ConfigurationService implements IConfigurationService {
  async getConfig(): Promise<IConfiguration> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch("/api/v1/env.json", { headers });
    const data = (await response.json()) as EditorKeyDTO;
    return {
      editorApiKey: data.editor_key,
    };
  }
}
