<template>
  <div class="d-flex flex-column align-items-center w-100 mt-5">
    <ProfileSummary :profileSummary="profileSummary" :userId="userId" />

    <PublicProfileDetails :profile="profile" />

    <PublicProfilePostingActivity :userId="userId" :userName="displayName" />

    <PublicProfilePostingActivityMobile
      :userId="userId"
      :userName="displayName"
    />
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import ProfileSummary from "@/components/ProfileSummary.vue";
import IProfile from "@/domain/IProfile";
import IProfileSummary from "@/domain/IProfileSummary";
import IUserService from "@/application/IUserService";
import PublicProfileDetails from "@/components/PublicProfileDetails.vue";
import PublicProfilePostingActivityMobile from "@/components/PublicProfilePostingActivityMobile.vue";
import PublicProfilePostingActivity from "@/components/PublicProfilePostingActivity.vue";
@Component({
  components: {
    PublicProfilePostingActivity,
    PublicProfilePostingActivityMobile,
    PublicProfileDetails,
    ProfileSummary,
  },
})
export default class PublicProfileView extends Vue {
  @Inject("IUserService") userService!: IUserService;

  @Prop() private userId!: string;

  private profile: IProfile | null = null;
  private profileSummary: IProfileSummary | null = null;

  mounted(): void {
    this.userService
      .getProfile(this.userId)
      .then((profile) => (this.profile = profile));
    this.userService
      .gerProfileSummary(this.userId)
      .then((profileSummary) => (this.profileSummary = profileSummary));
  }

  get displayName(): string | undefined {
    return this.profileSummary?.displayName;
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";
hr {
  max-width: $section-max-width;
  border-top: 0.125rem solid $grey-border;
  display: none;
  @media (min-width: $breakpoint-md) {
    display: block;
  }
}
</style>
