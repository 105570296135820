import { inject, singleton } from "tsyringe";
import ISecurityService from "@/application/ISecurityService";
import FetchUtils from "@/infrastructure/FetchUtils";
import IFileUploadService from "@/application/IFileUploadService";
import IUploadedFile from "@/domain/IUploadedFile";

type FileSelectedDTO = {
  id: number;
};

const DEFAULT_HTTP_HEADERS = {};

const CSRF_TOKEN_HEADER_NAME = "X-CSRFToken";

@singleton()
export default class FileUploadService implements IFileUploadService {
  constructor(
    @inject("ISecurityService") private securityService?: ISecurityService
  ) {}

  async uploadFile(
    name: string,
    wysiwygUuid: string,
    image: string,
    contentType: string
  ): Promise<IUploadedFile> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const csrfToken = this.securityService?.getToken();
    if (csrfToken) {
      headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("wysiwyg_uuid", wysiwygUuid);
    formData.append("file_attachment", image);
    formData.append("content_type", contentType);
    const response = await fetch("/api/v1/message-attachments", {
      method: "POST",
      body: formData,
      headers,
    });
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, response);
    if (error) {
      throw error;
    }
    return FileUploadService.toIUploadedImage(data as FileSelectedDTO);
  }

  public static toIUploadedImage(fileSelected: FileSelectedDTO): IUploadedFile {
    return {
      fileId: fileSelected.id,
    };
  }

  async deleteImage(wysiwygUuid: string, id: number): Promise<void> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const csrfToken = this.securityService?.getToken();
    if (csrfToken) {
      headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;
    }
    const response = await fetch(
      `/api/v1/message-attachments?messageUuid=${wysiwygUuid}&attachmentId=${id}`,
      {
        method: "DELETE",
        headers,
      }
    );
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    } else {
      return data;
    }
  }
}
