<template>
  <div class="card mb-md-5">
    <div class="card-header p-0" id="heading_time_zone_preference">
      <h2 class="mb-0">
        <button
          class="btn btn-link btn-block text-left d-flex d-md-block justify-content-between collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#collapse_time_zone_preference"
          aria-expanded="true"
          aria-controls="#collapse_time_zone_preference"
        >
          <span class="mr-0 mr-md-2">Time Zone Preference</span>
          <i class="fas fa-angle-up"></i>
        </button>
      </h2>
    </div>

    <div
      id="collapse_time_zone_preference"
      class="collapse"
      aria-labelledby="heading_time_zone_preference"
    >
      <div class="card-body p-3">
        <form v-if="displayTimeZoneForm">
          <div class="row">
            <div class="col-12 col-md-3">
              <select
                class="custom-select"
                v-model="newTimeZone"
                :disabled="saving"
              >
                <option selected disabled value="">
                  -- Select Time Zone --
                </option>
                <option
                  :value="timeZone"
                  v-for="(timeZone, key) in timeZones"
                  :key="`tz_${key}`"
                >
                  {{ timeZone }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-4 mt-md-0 mt-3">
              <button
                class="btn btn-primary"
                @click.prevent="save"
                :disabled="disableSaveButton"
              >
                Save changes
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </form>
        <LoadingSkeleton v-else width="100%" height="100px" radius="0" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Vue } from "vue-property-decorator";
import IProfileService from "@/application/IProfileService";
import ITimeZoneService from "@/application/ITimeZoneService";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";

@Component({
  components: { LoadingSkeleton },
})
export default class ProfileTimeZonePreferenceView extends Vue {
  @Inject("IProfileService") profileService!: IProfileService;
  @Inject("ITimeZoneService") timeZoneService!: ITimeZoneService;

  private originalTimeZone: string | null = null;
  private newTimeZone: string | null = null;
  private timeZones: Array<string> = [];

  private saving = false;

  private mounted() {
    const $timeZone = this.profileService.getTimeZone();
    $timeZone.subscribe((timeZone) => {
      this.originalTimeZone = timeZone?.timeZone ?? null;
      this.newTimeZone = this.originalTimeZone;
    });

    this.timeZoneService.getTimeZones().then((timeZones) => {
      this.timeZones = timeZones;
    });
  }

  private get displayTimeZoneForm(): boolean {
    return this.originalTimeZone !== null && this.timeZones.length > 0;
  }

  private get disableSaveButton(): boolean {
    return this.originalTimeZone === this.newTimeZone || this.saving;
  }

  private async save(): Promise<void> {
    this.saving = true;
    await this.profileService.saveTimeZone({
      timeZone: this.newTimeZone ?? "",
    });
    this.saving = false;
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.card {
  max-width: $section-max-width;
  width: 100%;
  box-shadow: none;
  border: 0;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2),
    0px 1px 3px rgba(50, 50, 71, 0.1);
  .card-header {
    background-color: #ffffff;

    button {
      text-decoration: none;
      i {
        transition-duration: 500ms;
        transition-property: transform;
      }
      &.collapsed {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }

  form {
    button {
      width: 100%;
      @media (min-width: $breakpoint-md) {
        width: 10rem;
      }
    }
  }
}
</style>
