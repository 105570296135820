import { inject, singleton } from "tsyringe";
import ISecurityService from "@/application/ISecurityService";
import FetchUtils from "@/infrastructure/FetchUtils";
import IMessageService from "@/application/IMessageService";
import IMessage from "@/domain/IMessage";
import { BehaviorSubject, concatMap, map, Observable, Subject } from "rxjs";
import IReply from "@/domain/IReply";
import { ajax } from "rxjs/internal/ajax/ajax";

type MessageDTO = {
  mailinglist: {
    name: string;
    display_name: string;
    description: string;
  };
  message_id_hash: string;
  subject: string;
  content: string;

  html_content: string;
  sender: {
    mailman_id: string;
    name: string;
    image_url: string;
  };
  date: string;
  get_votes: {
    likes: number;
    dislikes: number;
    status: string;
  };
  parent: object;
  thread_id: string;
  flagged_by_moderator: boolean;
};

type SendMessageDTO = {
  subject: string;
  mlistName: string;
  message: string;
  wysiwyg_uuid: string;
};

type ReplyDTO = {
  mailinglist: {
    name: string;
    display_name: string;
    description: string;
  };
  thread_id: number;
  message_id_hash: string;
  subject: string;
  content: string;
  html_content: string;
  sender: {
    mailman_id: string;
    name: string;
    image_url: string;
  };
  get_votes: {
    likes: string;
    dislikes: string;
  };
  date: string;
  thread_depth: number;
  thread_order: number;
  parent: number;
  likes: number;
  dislikes: number;
  attachments: Array<{
    name: string;
    content_type: string;
    size: string;
    counter: number;
  }>;
  wysiwyg_uuid: string;
  flagged_by_moderator: boolean;
};

type ResponseDTO = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<ReplyDTO>;
};

type ReplyQuery = {
  listId: string;
  threadId: number;
  limit: number;
  offset: number;
  sortBy: string;
};

type ResponseServerHeader = {
  responseStatus: number;
  server: string;
};

const DEFAULT_HTTP_HEADERS = {
  "Content-Type": "application/json",
};

const CSRF_TOKEN_HEADER_NAME = "X-CSRFToken";

@singleton()
export default class MessageService implements IMessageService {
  private replies$: BehaviorSubject<Array<IReply>> = new BehaviorSubject<
    Array<IReply>
  >([]);
  private replyQueryQueue$: Subject<ReplyQuery> = new Subject<ReplyQuery>();
  constructor(
    @inject("ISecurityService") private securityService?: ISecurityService
  ) {
    this.replyQueryQueue$
      .pipe(concatMap((replyQuery) => this._loadMore(replyQuery)))
      .subscribe((replies) => {
        const newReplies = [...this.replies$.getValue(), ...replies];
        this.replies$.next(newReplies);
      });
  }

  public async getMessage(listId: string, idHash: string): Promise<IMessage> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch(
      `/api/v1/mailing-list/${listId}/email/${idHash}/`,
      { headers }
    );
    const data = await response.json();

    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return MessageService.toIMessage(data as MessageDTO);
  }

  private static toIMessage(messageDTO: MessageDTO): IMessage {
    return {
      idHash: messageDTO.message_id_hash,
      subject: messageDTO.subject,
      content: messageDTO.content,
      htmlContent: messageDTO.html_content,
      sender: {
        mailmanId: messageDTO.sender.mailman_id,
        name: messageDTO.sender.name,
        imageUrl: messageDTO.sender.image_url,
      },
      date: messageDTO.date,
      likes: messageDTO.get_votes.likes,
      dislikes: messageDTO.get_votes.dislikes,
      mailingList: {
        name: messageDTO.mailinglist.name,
        displayName: messageDTO.mailinglist.display_name,
      },
      threadId: messageDTO.thread_id,
      flaggedByModerator: messageDTO.flagged_by_moderator,
    };
  }

  async sendMessage(
    subject: string,
    mlistName: string,
    message: string,
    wysiwygUuid: string
  ): Promise<{ responseStatus: number; server: string } | void> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const csrfToken = this.securityService?.getToken();
    if (csrfToken) {
      headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;
    }
    const response = await fetch("/api/v1/messages/", {
      method: "POST",
      headers,
      body: JSON.stringify({
        subject: subject,
        mlist_name: mlistName,
        message: message,
        wysiwyg_uuid: wysiwygUuid,
      }),
    });

    //some content blocked by cloudflare, if occurred throw an error for user
    const responseServerHeader = response.headers.get("Server");
    if (response.status === 403 && responseServerHeader === "cloudflare") {
      return {
        responseStatus: response.status,
        server: responseServerHeader,
      };
    }
    const data = response.json();
    //throw other errors
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    } else {
      window.location.replace(`/mailing-list/${mlistName}`);
    }
  }

  public static toMessage(sendMessage: SendMessageDTO): {
    subject: string;
    mailingList: string;
    htmlContent: string;
    wysiwygUuid: string;
  } {
    return {
      subject: sendMessage.subject,
      mailingList: sendMessage.mlistName,
      htmlContent: sendMessage.message,
      wysiwygUuid: sendMessage.wysiwyg_uuid,
    };
  }

  public async replyMessage(
    newThread: boolean,
    subject: string,
    message: string,
    messageIdHash: string,
    mlistFqdn: string,
    mlistName: string,
    mlistId: string,
    wysiwygUuid: string
  ): Promise<void> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const csrfToken = this.securityService?.getToken();
    if (csrfToken) {
      headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;
    }
    const response = await fetch("/api/v1/replies/", {
      method: "POST",
      headers,
      body: JSON.stringify({
        newthread: newThread,
        subject: subject,
        message: message,
        message_id_hash: messageIdHash,
        mlist_fqdn: mlistFqdn,
        mlist_name: mlistName,
        mlist_id: mlistId,
        wysiwyg_uuid: wysiwygUuid,
      }),
    });
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return data;
  }

  public getReplyList(): Observable<Array<IReply>> {
    return this.replies$.asObservable();
  }

  private static toIReplyList(replyDTO: ReplyDTO): IReply {
    return {
      idHash: replyDTO.message_id_hash,
      threadId: replyDTO.thread_id,
      subject: replyDTO.subject,
      content: replyDTO.content,
      html_content: replyDTO.html_content,
      threadDepth: replyDTO.thread_depth,
      threadOrder: replyDTO.thread_order,
      date: replyDTO.date,
      sender: {
        mailmanId: replyDTO.sender.mailman_id,
        name: replyDTO.sender.name,
        imageUrl: replyDTO.sender.image_url,
      },
      likes: replyDTO.get_votes.likes,
      dislikes: replyDTO.get_votes.dislikes,
      attachments: replyDTO.attachments.map((reply) => ({
        name: reply.name,
        contentType: reply.content_type,
        counter: reply.counter,
        size: reply.size,
      })),
      mailingList: {
        name: replyDTO.mailinglist.name,
        display_name: replyDTO.mailinglist.display_name,
        description: replyDTO.mailinglist.description,
      },
      wysiwygUuid: replyDTO.wysiwyg_uuid,
      flaggedByModerator: replyDTO.flagged_by_moderator,
    };
  }

  pushReply(index: number, reply: IReply, singleThreadReply: boolean): void {
    let i = 0;
    const newReplies: Array<IReply> = [];
    while (i < this.replies$.getValue().length) {
      newReplies.push(this.replies$.getValue()[i]);
      if (i === index) {
        singleThreadReply ? newReplies.unshift(reply) : newReplies.push(reply);
      }
      i++;
    }
    this.replies$.next(newReplies);
  }
  public async loadMore(
    listId: string,
    threadId: number,
    limit: number,
    offset: number,
    sortBy: string
  ): Promise<void> {
    this.replyQueryQueue$.next({
      listId,
      threadId,
      limit,
      offset,
      sortBy,
    });
  }
  private _loadMore(replyQuery: ReplyQuery): Observable<Array<IReply>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const { listId, threadId, limit, offset, sortBy } = replyQuery;
    return ajax({
      url: `/api/v1/mailing-list/${listId}/threads/${threadId}/replies?limit=${limit}&offset=${offset}&sort_by=${sortBy}`,
      method: "GET",
      headers: headers,
    }).pipe(
      map((ajaxResponse) => {
        const response = ajaxResponse.response as ResponseDTO;
        return response.results.map(MessageService.toIReplyList);
      })
    );
  }
}
