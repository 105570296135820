<template>
  <div>
    <div :class="confirmationReport ? 'modal-hide' : 'modal'">
      <div class="modal__content">
        <div class="modal__content__header">
          <p class="modal__content__header__title">Review reported post</p>
          <span class="modal__content__header__icon">
            <button type="button">
              <i class="fa fa-close" @click="onClose"></i>
            </button>
          </span>
        </div>
        <div class="modal__content__link-to-post">
          <div class="modal__content__link-to-post__label">
            <p class="font-weight-bold">Link to post:</p>
          </div>
          <div class="modal__content__link-to-post__subject">
            <span @click="originalMessageThread"
              ><a href="#">{{ subject }}</a>
            </span>
          </div>
        </div>
        <div class="modal__content__decision">
          <div class="modal__content__decision__label">
            <p class="font-weight-bold">Decision:</p>
          </div>
          <div class="modal__content__decision__options">
            <div class="form-check">
              <label class="form-check-label" for="radio1">
                <input
                  type="radio"
                  class="form-check-input"
                  name="moderatorDecision"
                  value="removedPost"
                  id="removedPost"
                  v-model="decisionSelected"
                />Remove post (with notification to original
                <span class="font-weight-bold"> post sender </span>)
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label" for="radio2">
                <input
                  type="radio"
                  class="form-check-input"
                  name="moderatorDecision"
                  value="keepPostWithNotification"
                  id="keepPostWithNotification"
                  v-model="decisionSelected"
                />Keep post (with notification to
                <span class="font-weight-bold"> post reporter </span>)
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label" for="radio3">
                <input
                  type="radio"
                  class="form-check-input"
                  name="moderatorDecision"
                  value="keepPostWithoutNotification"
                  id="keepPostWithoutNotification"
                  v-model="decisionSelected"
                />Keep post (NO notification to
                <span class="font-weight-bold"> post reporter </span>)
              </label>
            </div>
          </div>
        </div>
        <div
          v-if="decisionSelected !== 'keepPostWithoutNotification'"
          class="modal__content__email-template"
        >
          <span>Email template </span>
          <div class="style-select">
            <select class="form-control" v-model="emailTemplateSelected">
              <option
                v-for="template in emailTemplateBasedOnDecision"
                :key="template.id"
                :value="template.id"
              >
                {{ template.label }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="modal__content__reason"
          v-if="decisionSelected !== 'keepPostWithoutNotification'"
        >
          <div
            v-if="emailTemplateSelected === 'genericEmailTemplate'"
            class="modal__content__reason__textarea"
          >
            <label class="modal__content__reason__label">Reason</label>
            <textarea
              class="form-control"
              placeholder="Enter a description"
              v-model="reason"
            ></textarea>
          </div>
          <div class="mt-3">
            <span class="font-weight-bold">Preview: </span>
            <div
              v-if="decisionSelected === 'removedPost'"
              class="mt-2 ml-2 mr-2"
            >
              <span>
                Your mail to <b>‘{{ mList }}’</b> with the subject:
              </span>
              <div class="mt-2 mb-2 ml-4">
                <b>{{ subject }}</b>
              </div>
              <div class="mt-2">
                <span>has been reported. </span>
                <span>The moderator has reviewed it for removal.</span>
              </div>

              <div
                v-if="emailTemplateSelected === 'genericEmailTemplate'"
                class="mt-2"
              >
                <span>The message is being removed because:</span>
              </div>
              <div
                v-if="emailTemplateSelected === 'genericEmailTemplate'"
                class="mt-2 ml-4"
              >
                <b>{{ reason }}</b>
              </div>
              <div class="mt-2 mr-2">
                <span
                  >Please use discretion and make sure your future posts follow
                  the
                  <a
                    target="_blank"
                    href="https://www.apnic.net/community/participate/mailinglists/code-of-conduct/"
                    >Code of Conduct.</a
                  >
                  Repeat offences will result in being banned from the list
                  permanently.</span
                >
              </div>
            </div>

            <div
              v-if="decisionSelected === 'keepPostWithNotification'"
              class="mt-2 ml-2 mr-2"
            >
              <span>
                Your mail to <b>‘{{ mList }}’</b> with the subject:
              </span>
              <div class="mt-2 mb-2 ml-4">
                <b>{{ subject }}</b>
              </div>
              <div class="mt-2">
                <span
                  >The moderator has reviewed it and decided the post will be
                  kept.</span
                >
              </div>

              <div
                v-if="emailTemplateSelected === 'genericEmailTemplate'"
                class="mt-2"
              >
                <span>The message is being kept because:</span>
              </div>
              <div class="mt-2 ml-4">
                <b>{{ reason }}</b>
              </div>
              <div class="mt-2">
                <span
                  >Thank you for your discretion and helping us to keep Orbit a
                  safe place. Posts are reviewed by moderators following what is
                  outlined in the
                  <a
                    target="_blank"
                    href="https://www.apnic.net/community/participate/mailinglists/code-of-conduct/"
                  >
                    Code of Conduct</a
                  >
                  and reported posts are taken very seriously. Offenders are
                  issued a warning, with the post being removed. Repeat offences
                  will result in being banned from the list permanently.</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="modal__content__actions mt-4">
          <button class="modal__content__actions__cancel" @click="onClose">
            Cancel
          </button>
          <button
            class="modal__content__actions__confirm btn-primary"
            @click="onConfirm"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
    <ReportPostModalConfirmation
      v-if="confirmationReport"
      :confirmation-removed="confirmationRemoved"
      @onClickOk="onClickOk"
    />
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Inject } from "vue-property-decorator";
import IMessageReportService from "@/application/IMessageReportService";
import ReportPostModalConfirmation from "@/components/ReportPostModalConfirmation.vue";

const hostName = window.location.origin;

const removedPostEmailTemplateOptions = [
  {
    id: "genericEmailTemplate",
    label: "Generic, custom input for reason",
    active: true,
  },
  {
    id: "violateTemplate",
    label: "Violates Code of Conduct",
    active: false,
  },
];

const keepPostEmailTemplateOptions = [
  {
    id: "genericEmailTemplate",
    label: "Generic, custom input for reason",
    active: true,
  },

  {
    id: "doesNotViolateTemplate",
    label: "Does not violate Code of Conduct",
    active: false,
  },
];

@Component({ components: { ReportPostModalConfirmation } })
export default class ReportPostModeratorDecisionModal extends Vue {
  @Inject("IMessageReportService") messageReportService!: IMessageReportService;
  @Prop({ required: true }) subject!: string;
  @Prop({ required: true }) mList!: string;
  @Prop({ required: true }) messageIdHash!: string;
  @Prop({ required: true }) threadId!: string;
  @Prop({ required: true }) page!: number;
  @Prop({ required: true }) limit!: number;
  private decisionSelected = "";
  private reason = "";
  private confirmationReport = false;
  private confirmationRemoved = false;
  private emailTemplateSelected = "genericEmailTemplate";
  get emailTemplateBasedOnDecision(): {
    id: string;
    label: string;
    active: boolean;
  }[] {
    return this.decisionSelected === "removedPost"
      ? removedPostEmailTemplateOptions
      : keepPostEmailTemplateOptions;
  }

  private originalMessageThread(): void {
    window.open(
      `${hostName}/hyperkitty/list/${this.mList}/thread/${this.threadId}`,
      "_blank"
    );
  }

  private onClose(): void {
    this.$emit("onCancel");
  }
  private async onClickOk(): Promise<void> {
    this.confirmationReport = false;
    this.onClose();
  }

  private onshowModal(): void {
    this.$emit("showModal");
  }
  private onConfirm(): void {
    switch (this.decisionSelected) {
      case "removedPost":
        this.removedPost(this.emailTemplateSelected);
        this.confirmationRemoved = true;
        break;
      case "keepPostWithNotification":
        this.keepPostWithNotification(this.emailTemplateSelected);
        this.confirmationRemoved = false;
        break;
      case "keepPostWithoutNotification":
        // since we don't need to send notification to the reporter and original email sender
        this.keepPostWithoutNotification();
        this.confirmationRemoved = false;
        break;
      default:
        return;
    }
  }
  private removedPost(emailTemplateSelected: string): void {
    this.onEmailTemplateSelected(emailTemplateSelected);
  }
  private keepPostWithNotification(emailTemplateSelected: string): void {
    this.onEmailTemplateSelected(emailTemplateSelected);
  }
  private async keepPostWithoutNotification(): Promise<void> {
    await this.messageReportService.updateMessageReportStatus(
      this.mList,
      this.subject,
      this.decisionSelected,
      this.messageIdHash,
      "noTemplateSelected",
      this.reason
    );
    this.confirmationReport = true;
    this.$emit("onEmailTemplateSelected");
  }

  private async onEmailTemplateSelected(
    emailTemplateSelected: string
  ): Promise<void> {
    if (emailTemplateSelected === "genericEmailTemplate") {
      if (this.reason) {
        await this.messageReportService.updateMessageReportStatus(
          this.mList,
          this.subject,
          this.decisionSelected,
          this.messageIdHash,
          this.emailTemplateSelected,
          this.reason
        );
        this.reason = "";
      }
    } else if (emailTemplateSelected === "violateTemplate") {
      await this.messageReportService.updateMessageReportStatus(
        this.mList,
        this.subject,
        this.decisionSelected,
        this.messageIdHash,
        this.emailTemplateSelected,
        this.reason
      );
      this.confirmationReport = true;
    } else if (emailTemplateSelected === "doesNotViolateTemplate") {
      await this.messageReportService.updateMessageReportStatus(
        this.mList,
        this.subject,
        this.decisionSelected,
        this.messageIdHash,
        this.emailTemplateSelected,
        this.reason
      );
    } else if (emailTemplateSelected === "noTemplateSelected") {
      await this.messageReportService.updateMessageReportStatus(
        this.mList,
        this.subject,
        this.decisionSelected,
        this.messageIdHash,
        this.emailTemplateSelected,
        this.reason
      );
    }
    this.confirmationReport = true;
    this.$emit("onEmailTemplateSelected");
  }
}
</script>
<style scoped lang="scss">
@import "../assets/scss/variables.scss";
.modal-hide {
  display: none;
}
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 3001 !important;
  background-color: rgba(0, 0, 0, 0.5);
  &__content {
    position: relative;
    margin: 3% auto;
    padding: 32px;
    width: 100%;
    max-width: 574px;
    background-color: #fff;
    border-radius: 2px;
    gap: 32px;
    @media (max-width: $breakpoint-md) {
      max-width: 300px;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      &__title {
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
      }
      &__icon {
        color: #808080;
      }
      button {
        padding: 0;
        background: none;
        border: none;
      }
    }
    &__link-to-post {
      &__label {
        p {
          margin-bottom: 4px;
        }
      }
      &__subject {
        color: #006aa4;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
    &__decision {
      &__label {
        margin-bottom: 4px;
        p {
          margin-bottom: 8px;
        }
      }
      &__options {
        font-size: 14px;
        font-weight: 350;
        color: #4d4d4d;
        margin-left: 10px;
        .form-check {
          margin-bottom: 12px;
        }
      }
    }
    &__email-template {
      font-size: 14px;
      color: #4d4d4d;
      margin-bottom: 13px;
      select {
        appearance: none;
      }
      .style-select {
        background-color: red;
      }
    }
    &__email-template::after {
      display: flex;
      position: absolute;
      content: "";
      background: linear-gradient(
            45deg,
            hsla(0, 0%, 100%, 0) calc(50% - 1px),
            grey 50%,
            hsla(0, 0%, 100%, 0) calc(50% + 1px)
          )
          2px 5px/6px 6px no-repeat,
        linear-gradient(
            -45deg,
            hsla(0, 0%, 100%, 0) calc(50% - 1px),
            grey 50%,
            hsla(0, 0%, 100%, 0) calc(50% + 1px)
          )
          7px 5px/6px 6px no-repeat;
      height: 18px;
      width: 18px;
      right: 35px;
      top: 310px;
    }
    &__reason {
      font-size: 14px;
      &__label:after {
        content: "*";
        color: red;
      }
      &__textarea {
        textarea {
          width: 506px;
          height: 154px;
          @media (max-width: $breakpoint-md) {
            width: 250px;
          }
        }
      }
    }
    &__actions {
      display: flex;
      justify-content: space-between;
      margin-top: 11px;
      button {
        border: 1px;
        width: 246px;
        height: 44px;
      }
    }
  }
}
</style>
