import { Observable, Subject } from "rxjs";
import { IMobileMenuService } from "@/application/IMobileMenuService";
import { singleton } from "tsyringe";

@singleton()
export default class MobileMenuService implements IMobileMenuService {
  private subject$ = new Subject<void>();

  getOpenRequest(): Observable<void> {
    return this.subject$.asObservable();
  }

  pushOpenRequest(): void {
    this.subject$.next();
  }
}
