<template>
  <div class="mb-2 mb-md-0">
    <div v-if="message && message.sender.mailmanId">
      <a :href="`/profile/${message.sender.mailmanId}`" :title="linkTitle"
        >by {{ sendBy }}</a
      >
    </div>
    <span v-else>by {{ sendBy }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import IMessageService from "@/application/IMessageService";
import IMessage from "@/domain/IMessage";

@Component
export default class MessageSenderView extends Vue {
  @Inject("IMessageService") voteService!: IMessageService;
  @Prop({ required: true }) private messageIdHash!: string;
  @Prop({ required: true }) private mListFqdn!: string;

  private message: IMessage | null = null;

  private get linkTitle(): string {
    return this.message
      ? `See the profile for ${this.message.sender.name}`
      : "";
  }

  private get sendBy(): string {
    return this.message ? this.message.sender.name : "";
  }

  private async mounted(): Promise<void> {
    this.message = await this.voteService.getMessage(
      this.mListFqdn,
      this.messageIdHash
    );
  }
}
</script>
