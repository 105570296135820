import { inject, singleton } from "tsyringe";
import ISecurityService from "@/application/ISecurityService";
import IThreadService from "@/application/IThreadService";
import IThread from "@/domain/IThread";
import FetchUtils from "@/infrastructure/FetchUtils";

type EmailDTO = {
  mailinglist: {
    name: string;
    display_name: string;
    description: string;
  };
  message_id_hash: string;
  subject: string;
  content: string;
  html_content: string;
  sender: {
    mailman_id: string;
    name: string;
    image_url: string;
  };
  date: string;
  get_votes: {
    likes: number;
    dislikes: number;
    status: string;
  };
  parent: object;
  flagged_by_moderator: boolean;
};

type ThreadDTO = {
  thread_id: string;
  starting_email: EmailDTO;
  participants: Array<{
    mailman_id: string;
    name: string;
    image_url: string;
  }>;
  participants_count: number;
  emails_count: number;
  date_active: string;
};

type ResponseDTO = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<ThreadDTO>;
};

const DEFAULT_HTTP_HEADERS = {
  "Content-Type": "application/json",
};

@singleton()
export default class ThreadService implements IThreadService {
  constructor(
    @inject("ISecurityService") private securityService?: ISecurityService
  ) {}

  public async getLastThreads(
    limit: number,
    offset: number
  ): Promise<Array<IThread>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch(
      `/api/v1/threads?limit=${limit}&offset=${offset}`,
      { headers }
    );
    const data = await response.json();

    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return (data as ResponseDTO).results.map(ThreadService.toIThread);
  }

  public async getRecentThreads(
    mlist_fqdn: string,
    limit: number,
    offset: number
  ): Promise<Array<IThread>> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch(
      `/api/v1/mailing-list/${mlist_fqdn}/recent-threads?limit=${limit}&offset=${offset}`,
      { headers }
    );
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return (data as ResponseDTO).results.map(ThreadService.toIThread);
  }

  public async getMostUpvoteThreads(
    mlist_fqdn: string,
    limit: number,
    offset: number
  ): Promise<Array<IThread>> {
    const response = await fetch(
      `/api/v1/mailing-list/${mlist_fqdn}/most-upvote-threads?limit=${limit}&offset=${offset}`
    );
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return (data as ResponseDTO).results.map(ThreadService.toIThread);
  }

  public async getTopThreads(
    mlist_fqdn: string,
    limit: number,
    offset: number
  ): Promise<Array<IThread>> {
    const response = await fetch(
      `/api/v1/mailing-list/${mlist_fqdn}/top-threads?limit=${limit}&offset=${offset}`
    );
    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return (data as ResponseDTO).results.map(ThreadService.toIThread);
  }

  public async getFavorites(
    mlist_fqdn: string,
    limit: number,
    offset: number
  ): Promise<Array<IThread>> {
    const response = await fetch(
      `/api/v1/mailing-list/${mlist_fqdn}/favorites?limit=${limit}&offset=${offset}`
    );

    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return (data as ResponseDTO).results.map(ThreadService.toIThread);
  }

  public async getPostedTo(
    mlist_fqdn: string,
    limit: number,
    offset: number
  ): Promise<Array<IThread>> {
    const response = await fetch(
      `/api/v1/mailing-list/${mlist_fqdn}/posted-to?limit=${limit}&offset=${offset}`
    );

    const data = await response.json();
    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }
    return (data as ResponseDTO).results.map(ThreadService.toIThread);
  }

  public async getThread(mlist_fqdn: string, idHash: string): Promise<IThread> {
    const headers: HeadersInit | undefined = { ...DEFAULT_HTTP_HEADERS };
    const response = await fetch(
      `/api/v1/mailing-list/${mlist_fqdn}/thread/${idHash}/`,
      { headers }
    );
    const data = await response.json();

    const error = FetchUtils.findAnyError(response, data);
    if (error) {
      throw error;
    }

    return ThreadService.toIThread(data as ThreadDTO);
  }

  private static toIThread(thread: ThreadDTO): IThread {
    return {
      idHash: thread.thread_id,
      subject: thread.starting_email.subject,
      content: thread.starting_email.content,
      htmlContent: thread.starting_email.html_content,
      sender: {
        mailmanId: thread.starting_email.sender.mailman_id,
        name: thread.starting_email.sender.name,
        imageUrl: thread.starting_email.sender.image_url,
      },
      date: thread.starting_email.date,
      likes: thread.starting_email.get_votes.likes,
      dislikes: thread.starting_email.get_votes.dislikes,
      mailingList: {
        name: thread.starting_email.mailinglist.name,
        displayName: thread.starting_email.mailinglist.display_name,
      },
      participants: thread.participants.map((participant) => ({
        mailmanId: participant.mailman_id,
        name: participant.name,
        imageUrl: participant.image_url,
      })),
      participantsCount: thread.participants_count,
      emailsCount: thread.emails_count,
      isThread: true,
      dateActive: thread.date_active,
      threadId: thread.thread_id,
      flaggedByModerator: thread.starting_email.flagged_by_moderator,
    };
  }
}
