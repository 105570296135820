<template>
  <li
    class="nav-item nav-tooltip right"
    data-title="Manage lists"
    v-if="displayMenu"
  >
    <a
      v-if="manageThisListUrl"
      :href="manageThisListUrl"
      :class="{ 'nav-link': true, active: isMenuActive }"
      ><span class="fa fa-cog"></span>
      <span class="nav-item-text"> Manage this list </span></a
    >
    <a
      v-else
      href="/postorius/lists/"
      :class="{ 'nav-link': true, active: isMenuActive }"
      ><span class="fa fa-cog"></span
      ><span class="nav-item-text">Manage lists </span></a
    >
  </li>
</template>

<script lang="ts">
import MySubscriptions from "@/components/MySubscriptions.vue";
import { Component, Inject, Vue } from "vue-property-decorator";
import ISubscription from "@/domain/ISubscription";
import IProfileService from "@/application/IProfileService";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import ISecurityService from "@/application/ISecurityService";
import { filter } from "rxjs";
import IUser from "@/domain/IUser";
import { UserRole } from "@/domain/UserRole";

const HYPERKITTY_MAILING_LIST_PATH_MATCHER_REGEX = /^\/mailing-list\/([^/]+)\//;
const POSTORIUS_MAILING_LIST_PATH_MATCHER_REGEX = /^\/postorius\/lists\/.*/;
const ROLE_OWNER = "owner";
const ROLE_MODERATOR = "moderator";

@Component({ components: { MySubscriptions, LoadingSkeleton } })
export default class ManageListsView extends Vue {
  @Inject("IProfileService") profileService!: IProfileService;
  @Inject("ISecurityService") securityService!: ISecurityService;

  private currentUrlPath = "";
  private subscriptions: Array<ISubscription> | undefined = [];
  private user: IUser | null = null;

  private get displayMenu(): boolean {
    return (
      this.user?.roles?.includes(UserRole.SUPERUSER) ||
      this.isOwnerOfAnyList ||
      this.isModeratorOfAnyList
    );
  }

  private get isOwnerOfAnyList(): boolean {
    return !!this.subscriptions?.find((subscription) =>
      subscription.roles?.includes(ROLE_OWNER)
    );
  }

  private get isModeratorOfAnyList(): boolean {
    return !!this.subscriptions?.find((subscription) =>
      subscription.roles?.includes(ROLE_MODERATOR)
    );
  }

  private get manageThisListUrl(): string | undefined {
    const listName = this.currentUrlPath.match(
      HYPERKITTY_MAILING_LIST_PATH_MATCHER_REGEX
    )?.[1];

    if (!listName) {
      return undefined;
    }

    if (this.user?.roles?.includes(UserRole.SUPERUSER)) {
      return `/postorius/lists/${listName}/`;
    }

    const subscription = this.subscriptions?.find(
      (subscription) =>
        subscription.listName === listName &&
        (subscription.roles?.includes(ROLE_MODERATOR) ||
          subscription.roles?.includes(ROLE_OWNER))
    );

    return subscription
      ? `/postorius/lists/${subscription.listId}/`
      : undefined;
  }

  private get isMenuActive(): boolean {
    return POSTORIUS_MAILING_LIST_PATH_MATCHER_REGEX.test(this.currentUrlPath);
  }

  private async mounted(): Promise<void> {
    this.profileService
      .getSubscriptions()
      .pipe(filter((subscriptions) => !!subscriptions))
      .subscribe((subscriptions) => {
        this.subscriptions = subscriptions;
      });

    this.securityService.getUser().subscribe((user) => {
      this.user = user ?? null;
    });

    this.currentUrlPath = location.pathname;
  }
}
</script>
