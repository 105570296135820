<template>
  <VueApexCharts
    class="metrix-chart"
    width="100%"
    type="bar"
    :options="options"
    :series="series"
  ></VueApexCharts>
</template>

<script lang="ts">
import { IMetric } from "@/domain/IMetric";
import { ApexOptions } from "apexcharts";
import Vue, { PropType } from "vue";
import VueApexCharts from "vue-apexcharts";

export interface MetricsChartOptions {
  data: Array<IMetric>;
  title: string;
  yAxisLabel: string;
  seriesLabel: string;
}

export default Vue.extend({
  name: "MetricsChart",
  components: {
    VueApexCharts,
  },
  props: {
    chartOptions: {
      type: Object as PropType<MetricsChartOptions>,
      required: true,
    },
  },
  computed: {
    options(): ApexOptions {
      return {
        chart: {
          id: "metricsChart",
        },
        title: {
          text: this.chartOptions.title,
          align: "center",
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            color: "#007bbd",
          },
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "center",
          showForSingleSeries: true,
        },
        labels: this.chartOptions.data.map(this.formatYearMonth),
        xaxis: {
          categories: this.chartOptions.data.map(this.formatYearMonth),
        },
        yaxis: {
          title: {
            text: this.chartOptions.yAxisLabel,
          },
        },
      };
    },
    series(): { name: string; data: number[] }[] {
      return [
        {
          name: this.chartOptions.seriesLabel,
          data: [...this.chartOptions.data.map((metric) => metric.count)],
        },
      ];
    },
  },
  methods: {
    formatYearMonth(metric: IMetric): string {
      return `${metric.year}-${String(metric.month).padStart(2, "0")}`;
    },
  },
});
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";
@media (max-width: $breakpoint-sm) {
  .metrix-chart::v-deep {
    .apexcharts-title-text {
      font-size: 0.6rem;
    }

    .apexcharts-xaxis-label {
      font-size: 0.5rem;
    }
  }
}
</style>
